import { NiyamConfig } from '../react-i18next';

const niyam: NiyamConfig = {
  name: 'શ્રીજનમંગલ નામાવલિ/સ્તોત્રમ્',
  tabs: [
    {
      name: 'શ્રીજનમંગલ નામાવલિ',
      content: [
        '<b>શ્રીજનમંગલ નામાવલિ</b>',
        '૦૧. ૐ શ્રી શ્રીકૃષ્ણાય નમઃ',
        '૦૨. ૐ શ્રી શ્રીવાસુદેવાય નમઃ',
        '૦૩. ૐ શ્રી નરનારાયણાય નમઃ',
        '૦૪. ૐ શ્રી પ્રભવે નમઃ',
        '૦૫. ૐ શ્રી ભકિતધર્માત્મજાય નમઃ',
        '૦૬. ૐ શ્રી અજન્મને નમઃ',
        '૦૭. ૐ શ્રી કૃષ્ણાય નમઃ',
        '૦૮. ૐ શ્રી નારાયણાય નમઃ',
        '૦૯. ૐ શ્રી હરયે નમઃ',
        '૧૦. ૐ શ્રી હરિકૃષ્ણાય નમઃ',
        '૧૧. ૐ શ્રી ઘનશ્યામાય નમઃ',
        '૧૨. ૐ શ્રી ધાર્મિકાય નમઃ',
        '૧૩. ૐ શ્રી ભકિતનન્દનાય નમઃ',
        '૧૪. ૐ શ્રી બૃહદ્વ્રતધરાય નમઃ',
        '૧૫. ૐ શ્રી શુદ્ધાય નમઃ',
        '૧૬. ૐ શ્રી રાધાકૃષ્ણેષ્ટદવૈ તાય નમઃ',
        '૧૭. ૐ શ્રી મરુત્સુતપ્રિયાય નમઃ',
        '૧૮. ૐ શ્રી કાલીભરૈવાદ્યતિભીષણાય નમઃ',
        '૧૯. ૐ શ્રી જીતેન્દ્રિયાય નમઃ',
        '૨૦. ૐ શ્રી જીતાહારાય નમઃ',
        '૨૧. ૐ શ્રી તીવ્રવૈરાગ્યાય નમઃ',
        '૨૨. ૐ શ્રી આસ્તિકાય નમઃ',
        '૨૩. ૐ શ્રી યોગેશ્વરાય નમઃ',
        '૨૪. ૐ શ્રી યોગકલાપ્રવૃત્તયે નમઃ',
        '૨૫. ૐ શ્રી અતિધૈર્યવતે નમઃ',
        '૨૬. ૐ શ્રી જ્ઞાનિને નમઃ',
        '૨૭. ૐ શ્રી પરમહંસાય નમઃ',
        '૨૮. ૐ શ્રી તીર્થકૃતે નમઃ',
        '૨૯. ૐ શ્રી તૈર્થિકાર્ચિતાય નમઃ',
        '૩૦. ૐ શ્રી ક્ષમાનિધયે નમઃ',
        '૩૧. ૐ શ્રી સદોન્નિદ્રાય નમઃ',
        '૩૨. ૐ શ્રી ધ્યાનનિષ્ઠાય નમઃ',
        '૩૩. ૐ શ્રી તપઃપ્રિયાય નમઃ',
        '૩૪. ૐ શ્રી સિદ્ધેશ્વરાય નમઃ',
        '૩૫. ૐ શ્રી સ્વતન્ત્રાય નમઃ',
        '૩૬. ૐ શ્રી બ્રહ્મવિદ્યાપ્રવર્તકાય નમઃ',
        '૩૭. ૐ શ્રી પાષંડોચ્છેદનપટવે નમઃ',
        '૩૮. ૐ શ્રી સ્વસ્વરુપાચલસ્થિતયે નમઃ',
        '૩૯. ૐ શ્રી પ્રશાન્તમૂર્તયે નમઃ',
        '૪૦. ૐ શ્રી નિર્દોષાય નમઃ',
        '૪૧. ૐ શ્રી અસુરગુર્વાદિમોહનાય નમઃ',
        '૪૨. ૐ શ્રી અતિકારુણ્યનયનાય નમઃ',
        '૪૩. ૐ શ્રી ઉદ્ધવાધ્વપ્રવર્તકાય નમઃ',
        '૪૪. ૐ શ્રી મહાવ્રતાય નમઃ',
        '૪૫. ૐ શ્રી સાધુશીલાય નમઃ',
        '૪૬. ૐ શ્રી સાધુવિપ્રપ્રપૂજકાય નમઃ',
        '૪૭. ૐ શ્રી અહિંસયજ્ઞપ્રસ્તોત્રે નમઃ',
        '૪૮. ૐ શ્રી સાકારબ્રહ્મવર્ણનાય નમઃ',
        '૪૯. ૐ શ્રી સ્વામિનારાયણાય નમઃ',
        '૫૦. ૐ શ્રી સ્વામિને નમઃ',
        '૫૧. ૐ શ્રી કાલદોષનિવારકાય નમઃ',
        '૫૨. ૐ શ્રી સચ્છાસ્ત્રવ્યસનાય નમઃ',
        '૫૩. ૐ શ્રી સદ્યઃસમાધિસ્થિતિકારકાય નમઃ',
        '૫૪. ૐ શ્રી કૃષ્ણાર્ચાસ્થાપનકરાય નમઃ',
        '૫૫. ૐ શ્રી કૌલદ્વિષે નમઃ',
        '૫૬. ૐ શ્રી કલિતારકાય નમઃ',
        '૫૭. ૐ શ્રી પ્રકાશરુપાય નમઃ',
        '૫૮. ૐ શ્રી નિર્દમ્ભાય નમઃ',
        '૫૯. ૐ શ્રી સર્વજીવહિતાવહાય નમઃ',
        '૬૦. ૐ શ્રી ભકિતસમ્પોષકાય નમઃ',
        '૬૧. ૐ શ્રી વાગ્મિને નમઃ',
        '૬૨. ૐ શ્રી ચતુર્વર્ગફલપ્રદાય નમઃ',
        '૬૩. ૐ શ્રી નિર્મત્સરાય નમઃ',
        '૬૪. ૐ શ્રી ભકતવર્મણે નમઃ',
        '૬૫. ૐ શ્રી બુદ્ધિદાત્રે નમઃ',
        '૬૬. ૐ શ્રી અતિપાવનાય નમઃ',
        '૬૭. ૐ શ્રી અબુદ્ધિહૃતે નમઃ',
        '૬૮. ૐ શ્રી બ્રહ્મધામદર્શકાય નમઃ',
        '૬૯. ૐ શ્રી અપરાજીતાય નમઃ',
        '૭૦. ૐ શ્રી આસમુદ્રાન્તસત્કીર્તયે નમઃ',
        '૭૧. ૐ શ્રી શ્રિતસંસૃતિમોચનાય નમઃ',
        '૭૨. ૐ શ્રી ઉદારાય નમઃ',
        '૭૩. ૐ શ્રી સહજાનન્દાય નમઃ',
        '૭૪. ૐ શ્રી સાધ્વીધર્મપ્રવર્તકાય નમઃ',
        '૭૫. ૐ શ્રી કન્દર્પદર્પદલનાય નમઃ',
        '૭૬. ૐ શ્રી વૈષ્ણવક્રતુકારકાય નમઃ',
        '૭૭. ૐ શ્રી પંચાયતનસન્માનાય નમઃ',
        '૭૮. ૐ શ્રી નૈષ્ઠિકવ્રતપોષકાય નમઃ',
        '૭૯. ૐ શ્રી પ્રગલ્ભાય નમઃ',
        '૮૦. ૐ શ્રી નિઃસ્પૃહાય નમઃ',
        '૮૧. ૐ શ્રી સત્યપ્રતિજ્ઞાય નમઃ',
        '૮૨. ૐ શ્રી ભકતવત્સલાય નમઃ',
        '૮૩. ૐ શ્રી અરોષણાય નમઃ',
        '૮૪. ૐ શ્રી દીર્ઘદર્શિને નમઃ',
        '૮૫. ૐ શ્રી ષડૂર્મિવિજયક્ષમાય નમઃ',
        '૮૬. ૐ શ્રી નિરહંકૃતયે નમઃ',
        '૮૭. ૐ શ્રી અદ્રોહાય નમઃ',
        '૮૮. ૐ શ્રી ઋજવે નમઃ',
        '૮૯. ૐ શ્રી સર્વોપકારકાય નમઃ',
        '૯૦. ૐ શ્રી નિયામકાય નમઃ',
        '૯૧. ૐ શ્રી ઉપશમસ્થિતયે નમઃ',
        '૯૨. ૐ શ્રી વિનયવતે નમઃ',
        '૯૩. ૐ શ્રી ગુરવે નમઃ',
        '૯૪. ૐ શ્રી અજાતવૈરિણે નમઃ',
        '૯૫. ૐ શ્રી નિર્લોભાય નમઃ',
        '૯૬. ૐ શ્રી મહાપુરૂષાય નમઃ',
        '૯૭. ૐ શ્રી આત્મદાય નમઃ',
        '૯૮. ૐ શ્રી અખંડિતાર્ષમર્યાદાય નમઃ',
        '૯૯. ૐ શ્રી વ્યાસસિદ્ધાન્તબોધકાય નમઃ',
        '૧૦૦. ૐ શ્રી મનોનિગહ્રયુક્તિજ્ઞાય નમઃ',
        '૧૦૧. ૐ શ્રી યમદૂતવિમોચકાય નમઃ',
        '૧૦૨. ૐ શ્રી પૂર્ણકામાય નમઃ',
        '૧૦૩. ૐ શ્રી સત્યવાદિને નમઃ',
        '૧૦૪. ૐ શ્રી ગુણગ્રાહિણે નમઃ',
        '૧૦૫. ૐ શ્રી ગતસ્મયાય નમઃ',
        '૧૦૬. ૐ શ્રી સદાચારપ્રિયતરાય નમઃ',
        '૧૦૭. ૐ શ્રી પુણ્યશ્રવણકીર્તનાય નમઃ',
        '૧૦૮. ૐ શ્રી સર્વમંગલસદ્રુપ નાનાગુણવિચેષ્ટિતાય નમઃ',
        '\n<b>।। ઈતિ શ્રી જનમંગલ નામાવલિ સમાપ્ત ।।</b>',
      ],
    },
    {
      name: 'શ્રી જનમંગલ સ્તોત્રમ્',
      content: [
        '<b>શ્રી જનમંગલ સ્તોત્રમ્</b>',
        'વર્ણિવેષરમણીયદર્શનં મન્દહાસરુચિરાનનામ્બુજમ્ ।\nપૂજિતં સુરનરોત્તમૈર્મુદા ધર્મનન્દનમહં વિચિન્તયે ॥૧॥',
        'શ્રીકૃષ્ણઃ શ્રીવાસુદેવો નરનારાયણઃ પ્રભુઃ ।\nભક્તિધર્માત્મજોજન્મા કૃષ્ણો નારાયણો હરિઃ ॥૨॥',
        'હરિકૃષ્ણો ઘનશ્યામો ધાર્મિકો ભક્તિનન્દનઃ ।\nબૃહદ્‌વ્રતધરઃ શુદ્ધો રાધાકૃષ્ણેષ્ટદૈવતઃ ॥૩॥',
        'મરુત્સુતપ્રિયઃ કાલીભૈરવાદ્યતિભીષણઃ ।\nજિતેન્દ્રિયો જિતાહારસ્તીવ્રવૈરાગ્ય આસ્તિકઃ ॥૪॥',
        'યોગેશ્વરો યોગકલાપ્રવૃત્તિરતિધૈર્યવાન્ ।\nજ્ઞાની પરમહંસશ્ચ તીર્થકૃત્તૈર્થિકાર્ચિતઃ ॥૫॥',
        'ક્ષમાનિધિઃ સદોન્નિદ્રો ધ્યાનનિષ્ઠસ્તપઃ પ્રિયઃ ।\nસિદ્ધેશ્વરઃ સ્વતન્ત્રશ્ચ બ્રહ્મવિદ્યાપ્રવર્તકઃ ॥૬॥',
        'પાષણ્ડોછેદનપટુઃ સ્વસ્વરૂપાચલસ્થિતિઃ ।\nપ્રશાન્તમૂર્તિર્નિર્દોષોઽસુરગુર્વાદિમોહનઃ ॥૭॥',
        'અતિકારુણ્યનયન ઉદ્ધવાધ્વપ્રવર્તકઃ ।\nમહાવ્રતઃ સાધુશીલઃ સાધુવિપ્રપ્રપૂજકઃ ॥૮॥',
        'અહિંસયજ્ઞપ્રસ્તોતા સાકારબ્રહ્મવર્ણનઃ ।\nસ્વામિનારાયણઃ સ્વામી કાલદોષનિવારકઃ ॥૯॥',
        'સચ્છાસ્ત્રવ્યસનઃ સદ્યઃસમાધિસ્થિતિકારકઃ ।\nકૃષ્ણાર્ચાસ્થાપનકરઃ કૌલદ્વિટ્ કલિતારકઃ ॥૧૦॥',
        'પ્રકાશરૂપો નિર્દમ્ભઃ સર્વજીવહિતાવહઃ ।\nભક્તિસમ્પોષકો વાગ્મી ચતુર્વર્ગફલપ્રદઃ ॥૧૧॥',
        'નિર્મત્સરો ભક્તવર્મા બુદ્ધિદાતાઽતિપાવનઃ ।\nઅબુદ્ધિહૃદ્‌બ્રહ્મધામદર્શકશ્ચાપરાજિતઃ ॥૧૨॥',
        'આસમુદ્રાન્ત સત્કીર્તિઃ શ્રિતસંસૃતિમોચનઃ ।\nઉદારઃ સહજાનન્દઃ સાધ્વીધર્મપ્રવર્તકઃ ॥૧3॥',
        'કન્દર્પદર્પદલનો વૈષ્ણવક્રતુકારકઃ ।\nપઞ્ચાયતનસમ્માનો નૈષ્ઠિકવ્રતપોષકઃ ॥૧૪॥',
        'પ્રગલ્ભો નિઃસ્પૃહઃ સત્યપ્રતિજ્ઞો ભક્તવત્સલઃ ।\nઅરોષણો દીર્ઘદર્શી ષડૂર્મિવિજયક્ષમઃ ॥૧૫॥',
        'નિરહંકૃતિરદ્રોહ ઋજુઃ સર્વોપકારકઃ ।\nનિયામકશ્ચોપશમસ્થિતિર્વિનયવાન્ ગુરુઃ ॥૧૬॥',
        'અજાતવૈરી નિર્લોભો મહાપુરુષ આત્મદઃ ।\nઅખણ્ડિતાર્ષમર્યાદો વ્યાસસિદ્ધાન્તબોધકઃ ॥૧૭॥',
        'મનોનિગ્રહયુક્તિજ્ઞો યમદૂતવિમોચકઃ ।\nપૂર્ણકામઃ સત્યવાદી ગુણગ્રાહી ગતસ્મયઃ ॥૧૮॥',
        'સદાચારપ્રિયતરઃ પુણ્યશ્રવણકીર્તનઃ ।\nસર્વમંગલસદ્રૂપનાનાગુણવિચેષ્ટિતઃ ॥૧૯॥',
        'ઇત્યેતત્પરમં સ્તોત્રં જનમંગલસંજ્ઞિતમ્ ।\nયઃ પઠેત્તેન પઠિતં ભવેદ્વૈ સર્વમંગલમ્ ॥૨૦॥',
        'યઃ પઠેચ્છૃણુયાદ્‌ભક્ત્યા ત્રિકાલં શ્રાવયેચ્ચ વા ।\nએતત્તસ્ય તુ પાપાનિ નશ્યેયુઃ કિલ સર્વશઃ ॥૨૧॥',
        'એતત્સંસેવમાનાનાં પુરુષાર્થચતુષ્ટયે ।\nદુર્લભં નાસ્તિ કિમપિ હરિકૃષ્ણપ્રસાદતઃ ॥૨૨॥',
        'ભૂતપ્રેતપિશાચાનાં ડાકિનીબ્રહ્મરાક્ષસામ્ ।\nયોગિનીનાં તથા બાલગ્રહાદીનામુપદ્રવઃ ॥૨૩॥',
        'અભિચારો રિપુકૃતો રોગશ્ચાન્યોઽપ્યુપદ્રવઃ ।\nઅયુતાવર્તનાદસ્ય નશ્યત્યેવ ન સંશયઃ ॥૨૪॥',
        'દશાવૃત્યા પ્રતિદિનમસ્યાભીષ્ટં સુખં ભવેત્ ।\nગૃહિભિસ્ત્યાગિભિશ્ચાપિ પઠનીયમિદં તતઃ ॥૨૫॥',
        '\n<b>।। ઇતિ શ્રીશતાનન્દમુનિવિરચિતં શ્રીજનમંગલાખ્યં શ્રીહર્યષ્ટોત્તરશતનામસ્તોત્રં સંપૂર્ણમ્ ।।</b>',
      ],
    },
  ],
};

export default niyam;
