import { NiyamConfig } from '../react-i18next';

const niyam: NiyamConfig = {
  name: 'ઓરડા ના પદ',
  tabs: [
    {
      name: 'પદ ૧',
      content: [
        'આજ મારે ઓરડે રે, આવ્યા અવિનાશી અલબેલ; \nબાઈ મેં બોલાવીયા રે, સુંદર છોગાંવાળા છેલ ...૧',
        'નીરખ્યા નેણાં ભરી રે, નટવર સુંદર શ્રી ઘનશ્યામ; \nશોભા શી કહું રે, નિરખી લાજે કોટિક કામ ...૨',
        'ગૂંથી ગુલાબના રે, કંઠે આરોપ્યા મેં હાર; \nલઈને વારણાં રે, ચરણે લાગી વારમવાર ...૩',
        'આપ્યો મેં તો આદરે રે, બેસવા ચાકળિયો કરી પ્યાર; \nપૂછ્યા પ્રીતશું રે, બાઈ મેં સર્વે સમાચાર ...૪',
        'કહોને હરિ ક્યાં હતા રે, ક્યાં થકી આવ્યા ધર્મકુમાર; \nસુંદર શોભતા રે, અંગે સજીયા છે શણગાર ...૫',
        'પહેરી પ્રીતશું રે, સુરંગી સૂંથણલી સુખદેણ; \nનાડી હીરની રે, જોતાં તૃપ્ત ન થાયે નેણ ...૬',
        'ઉપર ઓઢીયો રે, ગૂઢો રેંટો જોયા લાગ; \nસજની તે સમે રે, ધન્ય ધન્ય નીરખ્યા તેનાં ભાગ્ય ...૭',
        'મસ્તક ઉપરે રે, બાંધ્યું મોળીડું અમૂલ્ય; \nકોટિક રવિ શશી રે, તે તો નાવે તેને તુલ્ય ...૮',
        'રેશમી કોરનો રે, કરમાં સાહ્યો છે રુમાલ; \nપ્રેમાનંદ તો રે, એ છબી નીરખી થયો નિહાલ ...૯',
      ],
    },
    {
      name: 'પદ ૨',
      content: [
        'સજની સાંભળો રે, શોભા વર્ણવું તેની તેહ; \nમૂર્તિ સંભારતાં રે, મુજને ઉપજ્યો અતિ સ્નેહ ...૧',
        'પહેર્યા તે સમે રે, હરિએ અંગે અલંકાર; \nજેવા મેં નિરખ્યા રે, તેવા વર્ણવું કરીને પ્યાર. ..૨',
        'બરાસ કપુરના રે, પહેર્યા હૈડે સુંદર હાર; \nતોરા પાઘમાં રે, તે પર મધુકર કરે ગુંજાર ...૩',
        'બાજુ બેરખા રે, બાંયે કપૂરના શોભિત; \nકડાં કપૂરનાં રે, જોતાં ચોરે સૌનાં ચિત્ત ...૪',
        'સર્વે અંગમાં રે, ઉઠે અત્તરની બહુ ફોર; \nચોરે ચિત્તને રે, હસતા કમળ નયનની કોર ...૫',
        'હસતા હેતમાં રે, સૌને દેતા સુખ આનંદ; \nરસરૂપ મૂર્તિ રે, શ્રીહરિ કેવળ કરુણા કંદ ...૬',
        'અદ્બુત ઉપમા રે, કહેતાં શેષ ન પામે પાર; \nધરીને મૂર્તિ રે, જાણે આવ્યો રસ શૃંગાર ...૭',
        'વાલપ વેણમાં રે, નેણાં કરુણામાં ભરપૂર; \nઅંગો અંગમાં રે, જાણે ઉગીયા અગણિત સૂર ...૮',
        'કરતા વાતડી રે, બોલી અમૃત સરખાં વેણ; \nપ્રેમાનંદનાં રે, જોતાં તૃપ્ત ન થાયે નેણ ...૯',
      ],
    },
    {
      name: 'પદ ૩',
      content: [
        'બોલ્યા શ્રી હરિ રે, સાંભળો નરનારી હરિજન; \nમારે એક વાર્તા રે, સહુને સંભળાવ્યાનું છે મન ...૧',
        'મારી મૂર્તિ રે, મારા લોક ભોગ ને મુક્ત; \nસર્વે દિવ્ય છે રે, ત્યાંતો જોયાની છે જુક્ત ...૨',
        'મારું ધામ છે રે, અક્ષર અમૃત જેનું નામ; \nસર્વે સામ્રથી રે, શક્તિ ગુણે કરી અભિરામ ...૩',
        'અતિ તેજોમય રે, રવિ શશિ કોટિક વારણે જાય; \nશીતળ શાંત છે રે, તેજની ઉપમા નવ દેવાય ...૪',
        'તેમાં હું રહું રે, દ્વિભુજ દિવ્ય સદા સાકાર; \nદુર્લભ દેવને રે, મારો કોઈ ન પામે પાર ...૫',
        'જીવ ઈશ્વર તણો રે, માયા કાળ પુરુષ પ્રધાન; \nસહુને વશ કરું રે, સહુનો પ્રેરક હું ભગવાન ...૬',
        'અગણિત વિશ્વની રે, ઉત્પત્તિ પાલન પ્રલય થાય; \nમારી મરજી વિના રે, કોઈથી તરણું નવ તોડાય ...૭',
        'એમ મને જાણજો રે, મારા આશ્રિત સૌ નરનારી; \nમેં તમ આગળે રે, વાર્તા સત્ય કહી છે મારી ...૮',
        'હું તો તમ કારણે રે, આવ્યો ધામ થકી ધરી દેહ; \nપ્રેમાનંદનો રે, વ્હલો વરસ્યા અમૃતમેહ ...૯',
      ],
    },
    {
      name: 'પદ ૪',
      content: [
        'વળી સૌ સાંભળો રે, મારી વાર્તા પરમ અનુપ; \nપરમ સિદ્ધાંત છે રે, સહુને હિતકારી સુખરૂપ ...૧',
        'સહુ હરિભક્તને રે, જાવું હોયે મારે ધામ; \nતો મને સેવજો રે, તમે શુદ્ધ ભાવે થઈ નિષ્કામ ...૨',
        'સહુ હરિભક્તને રે, રહેવું હોયે મારે પાસ; \nતો તમે મેલજો રે, મિથ્યા પંચવિષયની આશ ...૩',
        'મુજ વિના જાણજો રે, બીજા માયિક સહુ આકાર; \nપ્રીતિ તોડજો રે, જૂઠાં જાણી કુટુંબ પરિવાર ...૪',
        'સહુ તમે પાળજો રે, સર્વે દૃઢ કરી મારા નેમ; \nતમ પર રીઝશે રે, ધર્મ ને ભક્તિ કરશે ક્ષેમ ...૫',
        'સંત હરિભક્તને રે, દીધો શિક્ષાનો ઉપદેશ; \n લટકાં હાથનાં રે, કરતા શોભે નટવર વેશ ...૬',
        'નિજજન ઉપરે રે, અમૃત વરસ્યા આનંદ કંદ; \nજેમ સહુ ઔષધિ રે, પ્રીતે પોષે પૂરણચંદ ...૭',
        'શોભે સંતમાં રે, જેમ કાંઈ ઉડુગણમાં ઉડુરાજ; \nઈશ્વર ઉદય થયા રે, કળીમાં કરવા જનનાં કાજ ...૮',
        'એ પદ શીખશે રે, ગાશે સાંભળશે કરી પ્યાર; \nપ્રેમાનંદનો રે, સ્વામી લેશે તેની સાર ...૯',
      ],
    },
  ],
};

export default niyam;
