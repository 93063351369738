import { NiyamConfig } from '../react-i18next';

const niyam: NiyamConfig = {
  name: 'શાંતિ પાટ',
  tabs: [
    {
      name: 'ભક્તચિંતામણી પ્રકરણ ૨૯',
      content: [
        '<b>પૂર્વછાયો-</b>',
        'ત્યાર પછીની જે વાર્તા, તમે સાંભળો સહુ સુજાણ ।  \nત્યાગ વૈરાગ્ય જે નાથનો, તેનાં શિયાં કરું હું વખાણ ।।૧।।',
        'ભૂલ્યે પણ નિજ દેહને, માને નહિ કોઇ દિન । \nઇશ્વર ઇચ્છાએ તન રહ્યું, પણ પોતે ન કર્યું જતન ।।૨।।',
        'પછી પ્રભુજી બેઠા હતા, ગેહેરી ગંગાને તીર । \nત્યાંથી ઉઠી ઉતરિયા, જે હતું અથાહ નીર ।।૩।।',
        'ઉત્તર દિશમાં ચાલવા, અતિ અંતરમાં છે આનંદ । \nઝર નગને નિઝરણે, ચાલ્યા ઘનશ્યામ સુખકંદ ।।૪।।',
        '<b>ચોપાઇ-</b>',
        'ચાલ્યા ઉત્તર દિશે દયાળ, નિધડક થઇ તતકાળ । \nમોટા મોટા પર્વત બે પાસે, જાણું અદ્રિ અડયા છે આકાશે ।।૫।।',
        'સામસામી ઝુકી છે શિખર્યો, બહુ બિયામણી ઘણી ઝર્યો । \nચાલે નિઝરણે નીર ઘણાં, થાય ઘોષ અખંડ તે તણા ।।૬।।',
        'જેમ પરસ્પર ઝુક્યા પહાડ, તેમ ઝકુંબી રહ્યાં છે ઝાડ । \nનમી રહ્યું કરાડયું કરાળ, તેમાં ચાલ્યા જાય છે દયાળ ।।૭।।',
        'સામી નદીયે ચાલ્યા છે શ્યામ, જે કોઇ સર્વેના આત્મારામ । \nત્યાં તો આડો આવ્યો છે અચળ, આવે તેની ગુફામાંથી જળ ।।૮।।',
        'વહે વેગમાં ધારા પ્રચંડ, થાય ઘોષ તેહનો અખંડ । \nત્રણ્યે કોરે જાવા નહિ જાગ્ય, વળે કેમ જેને છે વૈરાગ્ય ।।૯।।',
        'પછી બેઠા તિયાં ઘનશ્યામ, દિન અસ્ત પામ્યો એહ ઠામ । \nવળતું જોયું પૂર્વે વિલોકી, દીઠો પુરુષ ત્યાં એક અલોકી ।।૧૦।।',
        'તેણે વણપૂછે કહી વાત, કિયાં જાવું છે હે જગતાત । \nપછી હરિ બોલિયા છે ત્યાંઇ, મારે જાવું છે ઉત્તરમાંઇ ।।૧૧।।',
        'પણ તમે કોણ છો દયાળ, આવ્યા આણે સમે તતકાળ । \nત્યારે તે કહે સુણો ભગવાન, હિમાચળ હું મૂરતિમાન ।।૧૨।।',
        'એમ કહીને બતાવી વાટ, ગરો ગુફામાં વરણીરાટ । \nએમાં ચાલતાં આવશે મગ, એમ કહીને ન દેખાણો નગ ।।૧૩।। ',
        'પછી ચાલ્યા એમાં અવિનાશ, જેને નથી દેહનો અધ્યાસ । \nપેઠા ઘોર અંધારી ગુફામાં, આવતી જળધારાને સામા ।।૧૪।।',
        'ચાલતાં ચાલતાં વિત્યો પહોર, બીજાનું કેમ હૈયું રહે ઠોર । \nમાંહિ મોટા મોટા મણિધર, ક્રુમ ક્રચલા કરી રહ્યા ઘર ।।૧૫।।',
        'મીન મઘર્યો દાદુર જેમાં, નિઃશંક મને ચાલ્યા જાય તેમાં । \nપછી પામિયા એહનો પાર, નિસર્યા ઘોર ગુફાને બાર ।।૧૬।।',
        'ત્યાં તો આવ્યો છે એક ધ્રોહ, ઉંડો અથાહ જંતુસમોહ । \nવિત્યા ત્રણ દિવસ જો ત્યાંઇ, ફળ ફુલ મળ્યું નહિ કાંઇ ।।૧૭।।',
        'ભૂખ્યા પડી રહ્યા તિયાં રાત્ય, શું કહું એ ધીરજની વાત । \nસુતા નિઃશંક થઇ ગઇ રેણ, જાગ્યા પ્રભાતે કમળનેણ ।।૧૮।।',
        'નાહી સંધ્યા કરી તેહવાર, મળ્યાં ફળ ફુલ કર્યો આહાર । \nપછી ચાલ્યા ત્યાંથી થોડું ઘણું, મળ્યું એંધાણ મારગતણું ।।૧૯।।',
        'ત્યારે ચાલ્યા એ મારગ લઇ, તિયાં દિન વીતી ગયા કઇ । \nપછી પુલહ બ્રહ્માના સુતન, આવ્યું આશ્રમ તેનું પાવન ।।૨૦।।',
        'અતિ ચમત્કારી છે એહ, થાય સુખી સેવે જન જેહ ।\nતપ ફળ મળે તિયાં તરત, જીયાં તપ કર્યું આગે ભરત ।।૨૧।।',
        'મુમુક્ષુને છે સેવવા જેવું, જ્યાં શ્રીકૃષ્ણને છે નિત્ય રહેવું । \nચક્ર નદી જીયાં ચારે કોર, નાયા છે તેમાં ધર્મકિશોર ।।૨૨।।',
        'કરી ક્રિયા ત્યાં પાઠ પૂજન, કર્યું મુક્તનાથનું દર્શન । \nપછી ભરતે કર્યું તપ જીયાં, પોતે પણ બેઠા જઇ તિયાં ।।૨૩।।',
        'તેની પેઠયે આદર્યું છે તપ, તેના જેવો કરે નિત્ય જપ । \nજાણ્યું પામ્યા ભરત મૃગદેહ , માટે પોતે રહેછે નિસ્પ્રેહ ।।૨૪।।',
        'એમ સંગ તજ્યો જ્યારે બાર, પછી અંતરે કર્યો વિચાર । \nપુરંજનની કથા સંભારી, મુક્યો બુદ્ધિનો સંગ વિસારી ।।૨૫।।',
        'શુદ્ધ સ્વરૂપ આત્મા કહેવાય, પોતાતણું માન્યું છે તેમાંય । \nપછી ઉર્ધ્વ બાહુ કરી આપ, કરે ગાયત્રીનો નિત્ય જાપ ।।૨૬।।',
        'ધર્યું સૂર્યનારાયણનું ધ્યાન, ગંડકીમાં કરી નિત્ય સ્નાન । \nમુક્તનાથ સેવા મન ગમે, ફળ ફુલ જે મળે તે જમે ।।૨૭।।',
        'તેને જોઇ તપી ત્યાં રહેનાર, વિસ્મય પામે મન મોઝાર । \nકહે આતો પ્રહ્લાદ છે આપે, કાંતો ધ્રુને મુક્યા ફરી બાપે ।।૨૮।।',
        'કાંતો સ્વામી કાર્તિક કહીએ, કાંતો સનતકુમાર લહીએ । \nકાંતો સનકાદિક સુજાણ, કાંતો દત્તાત્રેય પરમાણ ।।૨૯।।',
        'ઋભુ કાંતો નારાયણ ઋષિ, તેહ વિના નહી આ તપસી । \nજોને કઠણ કરે તપ તને, તોય પડતા નથી મોળા મને ।।૩૦।।',
        'જુવો સુખ શોભા મુનિ ઇન્દ્ર, જાણું પૂરણમાસીનો ચંદ્ર । \nઆવા બ્રહ્મસૃષ્ટિમાં ન ભાળ્યા, તેતો આજ નજરે નિહાળ્યા ।।૩૧।।',
        'મોટું તપ મનુષ્યે ન થાય, તે આદર્યું છે અતિ ઉછાય । \nએને આગળ તપ આપણું, થયું ખેલવણું બાળતણું ।।૩૨।।',
        'દિસે બાળ પણ મોટા બહુ, જોઇ વિસ્મય પામ્યા છીએ સહુ । \nએમ માંહોમાંહિ કહે મુનિ, જોઇ તપશા તેહ પ્રભુની ।।૩૩।।',
        'એવું તપ જોઇ બીજા જન, પ્રભુ દુઃખે દુઃખાણા છે મન । \nભક્તિ ધર્મ દિવ્ય દેહ ધરી, રહે છે પાસે દોય હેતે કરી ।।૩૪।।',
        'અતિ કૃશ ઉભા એક પગે, નથી દેખી શકતા તે દ્રગે । \nજાણ્યું લડથડી પડશે નાથ, ખમાંખમાં કહિ દિયે હાથ ।।૩૫।।',
        'અતિ હેત છે હરિને માથે, તેણે દંપતી રહે છે સાથે । \nદેહ દુર્બળ દેખ્યું ન જાય, રતિ રુધિર નહિ તન માંય ।।૩૬।।',
        'અંગોઅંગની જે સર્વે નાડી, તેતો થઇ રહી છે ઉઘાડી । \nદિસે અસ્થિ આમિષ ન દિસે, ત્વચા ચોટી રહિ છે તે વિષે ।।૩૭।।',
        'દેહ જોતાં દેહ નવ રહે, એમ જન જોઇ સહુ કહે । \nએવે શરીરે આદર્યું તપ, કરે છે મુખે ગાયત્રીજપ ।।૩૮।।',
        'તેમાં ધરે છે સૂર્યનું ધ્યાન, ભાવે કરી પોતે ભગવાન । \nકરે છે એવું તપ હમેશ, દેવા તપસ્વીને ઉપદેશ ।।૩૯।।',
        'એમ તપ કર્યું માસ ચાર, સહિ મેઘ તણી ઘણી ધાર । \nકરી તેમાં ઉપાસના ઘણી, તેતો સૂર્યનારાયણ તણી ।।૪૦।।',
        'એમ કરતાં એકાદશી જેહ, આવી પ્રબોધિની નામે તેહ । \nત્યારે અતિ આનંદ વધારી, કર્યું જાગરણ જામિની સારી ।।૪૧।।',
        'ત્યારે સૂર્યનારાયણે ત્યાંય, દીધું દર્શન એ નિશિમાંય । \nઅતિ સુંદર તન સ્વરૂપ, અંગો અંગે શોભે છે અનુપ ।।૪૨।।',
        'દોય કર કમળ છે હાથે, નંગજડીત મુગટ માથે । \nકનક કડાં છે કરમાં કાજુ, દોય ભુજાએ બાંધ્યા છે બાજુ ।।૪૩।।',
        'કાને કુંડળ શોભે છે સાર, તેજ તેજ તેજના અંબાર । \nહાસ્ય સહિત શોભે વદન, તેમાં કરુણાએ ભર્યાં લોચન ।।૪૪।।',
        'એવાં સૂર્યે દીધાં દરશન, અતિ આપે થઇને પ્રસન્ન । \nતેને દેખી ઉઠયા હરિ તરત, કર્યા ભક્તિએ શું દંડવત ।।૪૫।।',
        'થયા ગદ્ ગદ્ કંઠે વર્ણી, પ્રેમે ભૂલ્યા શુદ્ધ તનતણી । \nઆંખ્યે આંસુ રોમાંચિત તન, જોડી હાથ કરે છે સ્તવન ।।૪૬।।',
        'તમે તેજ પુંજ મારતંડ, નિજતેજે પ્રકાશો બ્રહ્માંડ । \nધરી કશ્યપ ઘેર અવતાર, એવા તમે તેને નમસ્કાર ।।૪૭।।',
        'તમારે ઉગવે કરી દયાળુ, થાય સર્વે જગત સુખાળુ । \nતેમાં પાપી પીડાય અપાર, એવા તમે તેને નમસ્કાર ।।૪૮।।',
        'તમારે ઉગવે કરી કહું, કરે કાળની ગણના સહુ । \nનિકર નોય કાંઇ નિરધાર, એવા તમે તેને નમસ્કાર ।।૪૯।।',
        'તમારે ઉગવે કરી આપ, સર્વે પ્રાણીના બાંધ્યા છે માપ । \nદેવ દાનવ ને નરનાર, એવા તમે તેને નમસ્કાર ।।૫૦।।',
        'તમે પ્રભુ પ્રગટ છો દેવ, સહુ જન જાણે છે એ ભેવ । \nનથી છાનો પ્રતાપ લગાર, એવા તમે તેને નમસ્કાર ।।૫૧।।',
        'એવી સ્તુતિ કરી જોડી કર, ત્યારે ભાવે બોલ્યા ભાસ્કર । \nમાગો હરિ મુપાસેથી આજ, ત્યારે હરિ કહે માગું મહારાજ ।।૫૨।।',
        'કામ ક્રોધ દંભ લોભ મોહ, ઇંદ્રિ ગુણ આદિ જે સમોહ । \nતેથી રક્ષા કરજ્યો અમારી, જેણે રહીએ નૈષ્ઠિક બ્રહ્મચારી ।।૫૩।।',
        'વળી જ્યારે જ્યારે હું સંભારૂં , ત્યારે દર્શન થાય તમારૂં । \nએજ વર માગું છું તમથી, માયિક સુખ માગતો નથી ।।૫૪।।',
        'ત્યારે સૂર્ય કહે સુખદાઇ, તમવડે મારી છે મોટાઇ । \nઆવો તેજ પ્રતાપ છે મારો, તેતો સર્વે જાણો છે તમારો ।।૫૫।।',
        'નથી સમર્થ તમથી અમે, પણ થાશે જે માગ્યું છે તમે । \nએમ હરિને આપી વરદાન, પોતે થયા પછી અંતર્ધાન ।।૫૬।।',
        'પછી તપ સમાપતિ કરી, એહ ક્ષેત્ર વખાણે છે હરિ । \nરહ્યા તિયાં દ્વાદશીનો દન, પછી કર્યું છે ચાલવા મન ।।૫૭।।',
        'એમ ચરિત્ર કરે બહુનામી, જે કોઇ સર્વે ધામના ધામી । \nજેની આગન્યામાં અજ ઇશ, વિષ્ણુ વિબુધ શારદા શેષ ।।૫૮।।',
        'જેની આગન્યામાં માયા કાળ, સર્વે લોક વળી લોકપાળ । \nજેની આગન્યામાં વાયુ વ્યોમ, વળી કહીએ તેજ તોય ભોમ ।।૫૯।।',
        'એહ સર્વેના નિયંતા સ્વામી, કરે એમ ચરિત્ર બહુનામી । \nનરતન ધર્યું છે તે માટ, એમ વરતે છે વર્ણિરાટ ।।૬૦।।',
        '<b>ઇતિ શ્રીમદેકાન્તિકધર્મપ્રવર્તક શ્રીસહજાનંદસ્વામિ શિષ્ય નિષ્કુળાનંદ મુનિ વિરચિતે ભક્તચિંતામણી મધ્યે હરિતપશ્ચર્યા વર્ણન નામે ઓગણત્રિશમું પ્રકરણમ્ ।।૨૯।।</b>',
      ],
    },
    {
      name: 'ભક્તચિંતામણી પ્રકરણ ૩૦',
      content: [
        '<b>પૂર્વછાયો-</b>',
        'ત્યાર પછી કૃષ્ણ દેવની, કહું કથા અતિ રસાળ । \nચંચળ થયા ચાલવા, ઉત્તર દિશામાં દયાળ ।।૧।।',
        'નામી મસ્તક મુક્તનાથને, પછી નામ્યું મુક્તને શિશ । \nપ્રભાતે ઉઠી પધારિયા, એહ આશ્રમથી જગદીશ ।।૨।।',
        'નગ નદીયો તળાવ તિયાં, તરી ઉતર્યા તે પાર । \nમહા અરણ્ય જ્યાં મનુષ્ય નહિ, ચોંપે ચાલ્યા તેહ મોઝાર ।।૩।।',
        'હિમાચળ ભણી ચાલીયા, જોયાં તેની તળાટીનાં વન । \nઝાડ પહાડ જોઇ પૃથિવી, જોયાં વિવિધે વૃક્ષ સઘન ।।૪।।',
        '<b>ચોપાઇ-</b>',
        'ઝાડ પહાડ ઉંચા છે અપાર રે, જાણ્યું અડયા આકાશ મોઝાર રે । \nસામસામી શાખા સંકલાણી રે, એક બીજામાં ઘણી ઘુંચાણી રે ।।૫।।',
        'વળી વિધ્યે વિધ્યે વેલી જેહ રે, એક બીજામાં ઉરઝી તેહ રે । \nવન વેલી ઘુંચાણી છે ઘાટી રે, જેને જોઇ છાતિ જાય ફાટી રે ।।૬।।',
        'એવું ઘાટું વન છે વિષમ રે, જેમાં ન પડે રાત્યદિની ગમ રે । \nન દિસે ઉગી આથમે દન રે, એવું ઝાડે છે વન સઘન રે ।।૭।।',
        'તિયાં ફળ ફુલ ફુલ્યાં કઇ રે, કંદ મૂળતણો પાર નઇ રે । \nવળી સર સરિતા અપાર રે, અતિ અમળ જળ તે મોઝાર રે ।।૮।।',
        'વળી ગેહેરી ગુફા ત્યાં ઘણી રે, જાણું બહુ રહ્યાં મંદિર બણી રે । \nવળી પશુ ને પક્ષી ત્યાં ઘણાં રે, ફરે ટોળાં બોળાં તેહતણાં રે ।।૯।।',
        'સિંહ શાર્દૂલ કાવે કેસરી રે, કપિ કુરંગ ને કઇ કરી રે । \nગેંડા રોઝ ને મહિષા ઘણા રે, વ્યાઘ્ર વારાહ બહુ બિહામણા રે ।।૧૦।।',
        'સુરાગાયો ને સેમર શ્યાળ રે, શશા નોળ બોળા તિયાં વ્યાળ રે । \nજ્યારે બોલે પરસ્પર એહ રે, થાય શબ્દ ભયંકર તેહ રે ।।૧૧।।',
        'મનુષ્ય જાતિએ ત્યાં ન જવાય રે, જો જાય તો પાછું ન અવાય રે । \nએવા વનમાં એકાએક ફરે રે, અતિ ધીર કોઇથી ન ડરે રે ।।૧૨।।',
        'ભૂત પ્રેત દનુજ ને દૈત્ય રે, એવા મળે વનમાંહિ નિત્ય રે । \nયક્ષ રાક્ષસ રાક્ષસી જેહ રે, ભૈરવ ભૈરવી વૈતાલી તેહ રે ।।૧૩।।',
        'એવા અહોનિશ વનમાં રમે રે, તિયાં હરિ એકાએક ભમે રે । \nજાતાં જાતાં પડે રાત્ય જીયાં રે, સુવે નિર્ભય થઇને તિયાં રે ।।૧૪।।',
        'એમ જોતાં તે વન સમગ્ર રે, આવ્યું એક ત્યાં બુટોલ નગ્ર રે । \nતેનો રાજા મહાદત્ત નામ રે, સર્વે પર્વતિ રાજાનો શ્યામ રે ।।૧૫।।',
        'તેણે દીઠા ત્યાગી ઘનશ્યામ રે, અતિ હેતે રાખ્યા નિજધામ રે । \nકરે અતિ પ્રીત્યે નિત્ય સેવ રે, જાણે આ છે મોટા કોઇ દેવ રે ।।૧૬।।',
        'નૃપભગિની નામ મયાજી રે, દેખી હરિ થયા બહુ રાજી રે । \nકહે આતો મોટા કોઇ અતિ રે, નોય મનુષ્યની આવી ગતી રે ।।૧૭।।',
        'જોઇ હરિનાં મોટાં આચરણ રે, સેવે કલ્યાણ સારું તે ચરણ રે । \nપછી નાથે દયા કરી તેને રે, આપ્યું નિજ જ્ઞાન એહ બેને રે ।।૧૮।।',
        'જન્મ મરણતણું જાળ કાપ્યું રે, સુખ અંતરે અખંડ આપ્યું રે । \nરહ્યા તિયાં થોડા ઘણા દિન રે, પછી ત્યાંથી ચાલ્યા છે જીવન રે ।।૧૯।।',
        'તપ કરવા ઇશક છે અતિ રે, બીજી વાત તે નથી ગમતિ રે । \nશહેર પુર નગ્ર ઘોષ ગામ રે, નથી ગમતું રહેવાને એ ઠામ રે ।।૨૦।।',
        'મેડી મહોલ હવેલી આવાસ રે, તેમાં રહેતાં રહે છે ઉદાસ રે । \nમાટે વેગે ચાલ્યા ત્યાંથી વને રે, તૈયે રાજી થયા બહુ મને રે ।।૨૧।।',
        'મુક્તનાથથી આવ્યા એ અરણ્ય રે, તેને વીતી ગયા કાળ ત્રણ્ય રે । \nચાલ્યા ગહન વનને માંઇ રે, ખાવા ફળ ફુલ નિત્ય ત્યાંઇ રે ।।૨૨।।',
        'તે પણ મળે કે ન મળે ટાણે રે, તોય મન અધીર ન આણે રે । \nએવા થકા વિચરે છે વને રે, અતિ ત્યાગ વૈરાગ્ય છે તને રે ।।૨૩।।',
        'જાતાં ઉત્તર દિશને માંઇ રે, આવ્યો વડ રૂડો એક ત્યાંઇ રે । \nત્યાંથી નદી તળાવ નિકટ રે, અતિ ઉંચો વિસ્તારે છે વટ રે ।।૨૪।।',
        'તેને આસપાસે ગજ ફરે રે, બીજા શબ્દ ભયંકર કરે રે । \nત્યાંથી ઉગમણું એક તાલ રે, વહે ઉત્તરમાં જળમાલ રે ।।૨૫।।',
        'નડ થુંબડે વિટયો છે વડ રે, બેઠા દીઠા યોગી તેને થડ રે । \nમૃગાજિન પર બેઠા આપ રે, માથે જટા મોટી છે નિષ્પાપ રે ।।૨૬।।',
        'આચ્છાદને ઢાંકેલ કૌપીન રે, નથી તે પણ વસ્ત્ર નવીન રે । \nતેહ વિના નથી બીજું પાસ રે, માયિક સુખથી છે ઉદાસ રે ।।૨૭।।',
        'શાળગ્રામની સેવા કીધી છે રે, ગીતા પાઠ કરવા લીધી છે રે । \nએવા ગોપાળયોગી ઉદાર રે, તેને નાથે કર્યો નમસ્કાર રે ।।૨૮।।',
        'ત્યારે ઉભા થઇ યોગીરાય રે, મળ્યા હેત આણિ ઉરમાંય રે । \nજેમ વહાલાં વેગળેથી આવે રે, તેને મળે જેમ અતિ ભાવે રે ।।૨૯।।',
        'એમ પામ્યા છે અતિ આનંદ રે, પછી મળી બેઠા મુનિ ઇંદ રે । \nકહ્યું એક બીજાનું વૃતાંત રે, ત્યારે વાધિયું હેત અત્યંત રે ।।૩૦।।',
        'કહે આપણે રહેશું બે મળી રે, બોલ્યા પ્રભુજી એવું સાંભળી રે । \nકહ્યું તમે ગુરુ ને હું શિષ્ય રે, આપો રૂડો મને ઉપદેશ રે ।।૩૧।।',
        'રહ્યા ગોપાળયોગીને પાસ રે, કર્યો યોગશાસ્ત્રનો અભ્યાસ રે । \nકાવે જે કોઇ અષ્ટાંગ યોગ રે, શિખ્યા જેથી મટે ભવરોગ રે ।।૩૨।।',
        'મોટી બુદ્ધિવાળા ઘનશ્યામ રે, શિખ્યા યોગ અંગ કહું નામ રે । \nયમ નિયમ આસન જેહ રે, પ્રાણાયામ પ્રત્યાહાર તેહ રે ।।૩૩।।',
        'ધારણા વળી ધ્યાન જે કહીએ રે, અષ્ટમું અંગ સમાધિ લઇએ રે । \nતેમાં જુજવા ભેદ છે બહુ રે, શિખ્યા થોડે દિને હરિ સહુ રે ।।૩૪।।',
        'એકવાર સાંભળે છે જેહ રે, શિખી કરી દેખાડે છે તેહ રે । \nવળી શિખ્યા છે પ્રથમ પેલે રે, તે પણ કરી દેખાડે છે છેલે રે ।।૩૫।।',
        'બસ્તિ બે પ્રકારની લહીએ રે, નેતિ કુંજરક્રિયા તે કહીએ રે । \nનોળિ શંખપ્રક્ષાલન નામ રે, મોર્યે શિખ્યા છે એ ઘનશ્યામ રે ।।૩૬।।',
        'તેતો સર્વે દેખાડે છે કરી રે, ગુરુ ગોપાળયોગીને હરિ રે । \nજોઇ ગુરુ કરે છે વિચાર રે, નોય મનુષ્ય આ નિરધાર રે ।।૩૭।।',
        'આતો કૃષ્ણ છે ગોલોકપતિ રે, આવ્યા છે પોતે ધરી મૂરતિ રે । \nઆવો હું જે અતિ નિસ્પૃહ રે, તેને ન થાય બીજે સનેહ રે ।।૩૮।।',
        'માટે જાણું છું જરૂર કૃષ્ણ રે, એવે ભાવે કરે નિત્ય દ્રષ્ણ રે । \nએમ પરસ્પર ગુરુપણું રે, રાખે એક બીજામાંઇ ઘણું રે ।।૩૯।।',
        'કંદ મૂળ ફળ ફુલ વળી રે, જમે આનંદે એકઠા મળી રે । \nએમ વિત્યું એ સ્થાનકે વર્ષ રે, કરી ઉગ્ર તપ થયા કૃષ રે ।।૪૦।।',
        'શીત ઉષ્ણ ને મેઘની ધાર રે, સહ્યું સર્વે શરીર મોઝાર રે । \nએમ તપ કરે વનમાંય રે, અતિ ધીરજ્ય પર્વતપ્રાય રે ।।૪૧।।',
        'એવા યોગી મોટા જે દયાળ રે, જોઇ પાસે વસ્યા પશુપાળ રે । \nરહ્યા ગાયો તણા ઘોષ કરી રે, ત્યાં તો કેડયે પડિયા કેસરી રે ।।૪૨।।',
        'નિત્ય કરે તે ગાયોની ઘાત રે, તેની કહી યોગી આગે વાત રે । \nકહે ગોવાળ અમે અનાથ રે, અમારે છે એ ગાયો મિરાંથ રે ।।૪૩।।',
        'તેને વાઘ કરશે જો નાશ રે, ત્યારે અમારે સઇ ગુંજાશ રે । \nએવી સાંભળી દીનતા વાણી રે, બોલ્યા યોગી તેને દુઃખી જાણી રે ।।૪૪।',
        'કહે માં બિયો તમે ગોવાળ રે, કરશે હરિ સહુની રખવાળ રે । \nએમ કહી લીધો શંખ હાથે રે, વજાડિયો છે તે યોગીનાથે રે ।।૪૫।।',
        'જેટલામાં સંભળાણો શબ્દ રે, ભાગ્યાં હિંસક મુકી એ હદ રે । \nવળી ત્યાં વસતાતા જે વાઘ રે, તેણે પણ કર્યું વન ત્યાગ રે ।।૪૬।।',
        'રહ્યા સુખે ગાયો ને ગોવાળ રે, પ્રભુ ગૌ બ્રાહ્મણ પ્રતિપાળ રે । \nરહ્યા કાંઇક પોતે પછી ત્યાંય રે, વાંચે ગીતાનો બીજો અધ્યાય રે ।।૪૭।।',
        'પછી આત્માનું શુદ્ધ સ્વરૂપ રે, નિશ્ચે કરીયું જેવું છે રૂપ રે । \nપ્રત્યાહાર કરી મહામતિ રે, ઇંદ્રિ પ્રાણ અંતઃકરણવૃત્તિ રે ।।૪૮।।',
        'તેને આતમામાં વાળી લીધી રે, પછી ધીરજે ધારણા કીધી રે । \nએમ કેટલાક કાળ ગયા રે, આતમાને વિષે સ્થિર રહ્યા રે ।।૪૯।।',
        'ધ્યાનયોગ તેની જે સમાધિ રે, તેની પકવદશા અતિ સાધિ રે । \nકહ્યું એમ યોગીને જીવને રે, એવી ક્રિયાઓ કરે છે વને રે ।।૫૦।।',
        '<b>ઇતિ શ્રીમદેકાન્તિકધર્મપ્રવર્તક શ્રીસહજાનંદસ્વામિ શિષ્ય નિષ્કુળાનંદ મુનિ વિરચિતે ભક્તચિંતામણી મધ્યે શ્રીહરિ ગોપાળયોગીને મળ્યા એ નામે ત્રિશમું પ્રકરણમ્ ।।૩૦।।</b>',
      ],
    },
    {
      name: 'ભક્તચિંતામણી પ્રકરણ ૩૧',
      content: [
        '<b>પૂર્વછાયો-</b>',
        'વળી કહું એક વારતા, સુણો સહુ થઇ સાવધાન । \nકૃપાનિધિ કૃષ્ણદેવનાં, કહું ચરિત્ર અમૃત સમાન ।।૧।।',
        'નાથ પ્રતાપે નિજ આતમા, દેખે અખંડરૂપે અનુપ । \nતેની બ્રહ્મ સાથે કરી એકતા, પછી થયા બ્રહ્મસ્વરૂપ ।।૨।।',
        'એવા યોગને શિખિયા, ગોપાળયોગી જેહ । \nસ્નેહે કરી શ્રીહરિએ, તેને શિખવિયું છે તેહ ।।૩।।',
        'તેણે કરી તર્ત થયા, યોગી તે બ્રહ્મસ્વરૂપ । \nઅતિ પ્રકાશક આતમા, તેહ જાણ્યું પોતાનું રૂપ ।।૪।।',
        '<b>ચોપાઇ-</b>',
        'પછી યોગીને જણાણું એમ, આને મનુષ્ય કહેવાય કેમ । \nથયું જ્ઞાન યથારથ જ્યારે, બોલ્યા ગોપાળયોગી તે વારે ।।૫।।',
        'નરનારાયણ ઋષિરાય, થઇ બ્રહ્મચારી આવ્યા આંય । \nબહુ જીવનાં કરવા કાજ, આપે પ્રગટ થયા મહારાજ ।।૬।।',
        'એમ નિશ્ચે કર્યો નિરધાર, નારાયણ તે આ બ્રહ્મચાર । \nએવા જાણી પછી ધર્યું ધ્યાન, ચોટી વૃત્તિ મૂર્તિમાં એકતાન ।।૭।।',
        'તેણે વિસરિયો નિજ દેહ, થયો મૂર્તિમાં અતિ સનેહ । \nજાણ્યું આપ મળ્યું સુખ મોટું, બીજું સરવે લાગ્યું છે ખોટું ।।૮।।',
        'એવા મોટા યોગી જે ગોપાળ, તેતો ભુલ્યા દેહ થોડે કાળ । \nઅતિ વિસ્મૃતિ થઇ જ્યારે, મુક્યો માયિક દેહને ત્યારે ।।૯।।',
        'પછી શ્રીકૃષ્ણ દેવ પ્રતાપે, ગયા ગોલોકમાં યોગી આપે । \nપછી બ્રહ્મચારી નીલકંઠે, કરી ક્રિયા તેની રૂડી પેઠે ।।૧૦।।',
        'પછી મુકી પોતે એહ સ્થાન, ચાલ્યા પૂરવમાં ભગવાન । \nરહી અખંડ બ્રહ્મસ્વરૂપ, ચાલ્યા આપે આપ બ્રહ્મરૂપ ।।૧૧।।',
        'નાસાગ્ર દ્રષ્ટિ કરીને સ્થિર, ચાલ્યા જેમ કમાનનો તીર । \nકૃષ્ણમાંહિ છે દ્રષ્ટિ અખંડ, નથી દેખતા પિંડ બ્રહ્માંડ ।।૧૨।।',
        'દિશ વિના નથી બીજો રાહ, ગયા ત્યાં થકી આદિવારાહ । \nતિયાં ત્રણ દિવસ પોતે રહ્યા, ત્યાંના વાસીને દર્શન થયાં ।।૧૩।।',
        'નિરખી આનંદ પામિયાં અતિ, જાણું આવ્યા શું આ બૃહસ્પતિ । \nએમ જનને આનંદ આલી, પછી ત્યાં થકી નિસર્યા ચાલી ।।૧૪।।',
        'ગયા બંગદેશમાં દયાળ, આવ્યું સીરપુર શહેર વિશાળ । \nસિદ્ધવલ્લભ તેનો છે રાયે, ગયા પોતે ત્યાં અણઇચ્છાયે ।।૧૫।।',
        'તે નરેશે પ્રાર્થના કરી, રાખ્યા ચોમાસું ત્યાં ભાવ ભરી । \nદીઠા અતિ ત્યાગી એકાએક, રાખ્યો પાસળે એક સેવક ।।૧૬।।',
        'તેનું નામ છે ગોપાળદાસ, કરે ટેલ્ય રહે નિત્ય પાસ । \nતિયાં બીજા ભેખધારી બહુ, રાખ્યા ચોમાસું કરવા સહુ ।।૧૭।।',
        'તે તમોગુણી મંત્રઅધ્યાસી, સર્વે ક્ષુદ્રદેવના ઉપાસી । \nતેના જુજવા જુજવા વેશ, કોઇ મુંડેલ કેનેક કેશ ।।૧૮।।',
        'કોઇ નાગા કોઇને કૌપીન, કોઇ ન રાખે વસ્ત્ર નવીન । \nતેમાં સોએક તપસ્વી સઇ, તેતો બેસે તડકામાં જઇ ।।૧૯।। ',
        'કોઇ વર્ણી ને કોઇ સન્યાસી, કોઇ હંસ ને કોઇ ઉદાસી । \nકોઇ કહે મુખે કાળી કાળી, કોઇ કહેતું બેચરાવાળી ।।૨૦।।',
        'કોઇ ભૈરવ ભૈરવ રહ્યા ભણી, કોઇ ભજે ભવાની જોગણી । \nકોઇ મુનિ ને કોઇક બોલે, કોઇ અહોનિશ આંખ્ય ન ખોલે ।।૨૧।।',
        'કૈક ખાખી કૈક હુડધંગા, એમ મળ્યા બહુ અડબંગા । \nથયા સિદ્ધ ભુંશી બહુ સેલી, ભંગી જંગી ભેળા થયા ફેલી ।।૨૨।।',
        'તેને રાજા જાણી મોટાસિદ્ધ, આપે રસોઇયો રૂડી વિદ્ધ । \nઆસન સારુ આપી ગાદલાં, કરે સનમાન રાજા ભલાં ।।૨૩।।',
        'ત્યારે યોગી બોલે બળે બહુ, કરે વાત સિદ્ધાઇની સહુ । \nએમ કરતાં આવ્યો વરષાત, વાયો વાયુ થયો ઉતપાત ।।૨૪।।',
        'ખવે વિજળી વારમવાર, વરષે મેઘ તે મુશળધાર । \nગરજે ઘોર ને કડાકા કરે, ઉપરે જળ અખંડ ઝરે ।।૨૫।।',
        'એવી અખંડ મંડાણી એલી, ચાલ્યાં પૃથવીએ પુર રેલી । \nતેમાં પોઢી રહ્યા પોતે નાથ, તન પર ચડી રેતી હાથ ।।૨૬।।',
        'પછી સેવક આવી સવારે, કાઢયા કાદવ માંહિથી બારે । \nવર્ષે મેઘ મચી બહુ ઝડી, આંખ્ય ઉઘડે નહિ એક ઘડી ।।૨૭।।',
        'એમ ચારે માસ વુઠો ઘન, પહોંચે સિદ્ધાઇ કેટલા દન । \nઅતિ વર્ષાતે અસોયા થયા, પછી રાત્યે રાત્યે ભાગી ગયા ।।૨૮।।',
        'ધીરે ધીરે સિદ્ધ ગયા નાશી, કરવા લાગ્યા લોક તેની હાંસી । \nકહે મોટા સિદ્ધ ગયા ચાલી, આ જો પડયાં છે આસન ખાલી ।।૨૯।।',
        'એક બેઠા રહ્યા બ્રહ્મચારી, તેને જોઇ નમ્યાં નરનારી । \nકહે સિદ્ધ તો આ એક ખરા, બીજા દંભી ભાગી ગયા પરા ।।૩૦।।',
        'રાજા નમ્યો જાણી હરિ મોટા, બીજા સર્વેને જાણ્યા છે ખોટા । \nવાધ્યું પ્રભુજીનું બહુ માન, બીજાનું ન કરે સનમાન ।।૩૧।।',
        'તેણે કરી બળ્યા બીજા બહુ, આવ્યા મળી મારવાને સહુ । \nનાખી અડદ મંતરી મુઠું, પડયું જે જે કર્યું તે તે જુઠું ।।૩૨।।',
        'પછી સેવક હતો જે પાસ, કરતો સેવા જે ગોપાળદાસ । \nતેને માથે નાખી એણે મુઠય, પડયો ભૂમિએ ન ફેરે પુંઠય ।।૩૩।।',
        'આવ્યું મોઢે ફીણ તેને જોઇ, નહિ જીવે કહે સહુ કોઇ । \nપછી રાજાએ સિદ્ધ બોલાવી, કહ્યું આને લેવો જ ઉઠાવી ।।૩૪।।',
        'ત્યારે સિદ્ધને છે મરડ ભારી, કહ્યું જીવાડશે બ્રહ્મચારી । \nત્યારે રાયે આવી જોડયા હાથ, કહ્યું આને જીવાડીયે નાથ ।।૩૫।।',
        'પછી હરિ તેને પાસે જઇ, ઉઠાડયો શ્રીકૃષ્ણ મંત્ર કઇ । \nઉઠયો તર્ત લાગી નહિ વાર, પામ્યાં વિસ્મય સહુ નરનાર ।।૩૬।।',
        'કહે આતો છે પોતે શ્રીકૃષ્ણ, મોટાં ભાગ્ય થયાં એનાં દ્રષ્ણ । \nવળી એમ કહે નરનારી, કૃષ્ણ નહિ તો કૃષ્ણ ભક્ત ભારી ।।૩૭।।',
        'પછી મુઠય નાખીતિ જે સિદ્ધે, પડી તેને માથે ભલી વિધ્યે । \nપડયો પડાક પૃથિવી માંઇ, મુખમાં ગઇ ધુડય ભરાઇ ।।૩૮।।',
        'આવ્યું મોઢે ફીણ ફાટયું ડાચું, થયું જીવ્યાની કોરનું કાચું । \nપછી તેણે તેના સિદ્ધ લાવી, કર્યા ઉપાય બહુ બોલાવી ।।૩૯।।',
        'તેણે ફેર પડયો નહિ રતિ, ત્યારે કરી હરિને વિનતિ । \nપછી નાથ તેને પાસ આવી, કહી મંત્ર ને લીધો જીવાવી ।।૪૦।।',
        'ત્યારે સિદ્ધ રાજા સહુ મળી, કરી સ્તુતિ હરિજીની વળી । \nજાણ્યા રાજાએ મોટા છે સરે, થયો પરિવાર સહિત આશરે ।।૪૧।।',
        'જેજે હતો એ સિદ્ધનો વર્તા, મુઠય નાખી બાળી પેટ ભર્તા । \nતેતો નીલકંઠે ભાંગ્યો ભય, થયા મનુષ્ય સહુ નિરભય ।।૪૨।।',
        'લાવી વસ્ત્ર ધન આગે ધરે, હરિ ત્યાગી છે તેને શું કરે । \nએટલાકમાં વિપ્ર તૈલંગ, આવ્યો નારી સુત લઇ સંગ ।।૪૩।।',
        'ભણ્યો વેદ શાસ્ત્ર ને પુરાણ, પ્રસિદ્ધ વિપ્ર પૃથ્વી પ્રમાણ । \nઆવ્યો સિદ્ધવલ્લભરાય પાસ, મને દાન લેવાની છે આશ ।।૪૪।।',
        'પછી તે રાજા છે ધર્મવાન, આપ્યું ભણ્યો જાણી ભારે દાન । \nઆપ્યો હસ્તિ ને કાળ પુરુષ, લેતાં વિપ્ર થયો કાળો મષ ।।૪૫।।',
        'ગૌર મટીને થયો છે શ્યામ, ત્યારે નિંદા કરવા લાગ્યું ગામ । \nપછી હરિ પાસે વિપ્ર આવી, અતિ દિનતાએ વાણી કાવી ।।૪૬।।',
        'હે મહારાજ ! હું તો હતો દુઃખી, દાન લઇ થવા ગયો સુખી । \nત્યાં તો સામું દુઃખ થયું ઘણું, હવે શું માહાત્મ્ય જીવ્યાતણું ।।૪૭।।',
        'માટે ત્યાગીશ હું હવે તન, થાય તો તમે કરો જતન । \nપછી દુઃખી વિપ્ર હરિ જાણી, દયાળે દયા એ પર આણી ।।૪૮।।',
        'કહ્યો શ્રીકૃષ્ણનો મંત્ર કાને, મટી શ્યામ થયો ગૌર વાને । \nપછી બ્રાહ્મણ લાગ્યો પાય, કહ્યું રહ્યો હું મન્યખા માંય ।।૪૯।।',
        'પછી ગાતો પ્રભુજીના ગુણ, ગયો દેશ પોતાને બ્રાહ્મણ । \nએવા પ્રભુજી બહુ પ્રતાપી, કર્યાં સુખી બહુ દુઃખ કાપી ।।૫૦।।',
        'ત્યાગ વૈરાગ્ય ઉરમાં અતિ, સહુપર વર્તે મહામતિ । એમ \nકરતાં ગયું ચોમાસું, આવ્યો કાર્તિક ઉતર્યો આસુ ।।૫૧।।',
        'પછી હરિ ને બીજા જે સિદ્ધ, તેને પૂજ્યા નૃપે બહુ વિદ્ધ । \nપછી ચાલ્યા છે ત્યાં થકી સહુ, હરિસંગે બીજા સિધ્ધ બહુ ।।૫૨।।',
        'આવ્યા કામાક્ષિદેવીની ઝાડીએ, ઉતર્યા સિદ્ધ સહુ વાડીએ । \nપછી રસોઇ કરવા કાજ, કર્યો ભેળો સરવે સમાજ ।।૫૩।।',
        'તેને સમીપે છે એક ગામ, વશે દ્વિજ ત્યાં પિબેક નામ । \nસિદ્ધમંડળ આવ્યું સાંભળી, ઉઠયો તર્ત તિયાં થકી બળી ।।૫૪।। ',
        'કહે સિદ્ધાઇ એની ચુંથી નાખું, કરી ગુલામ ને ઘેર રાખું । \nજોજ્યો માતાજીએ કરી મેર, આવ્યાં વણગોત્યાં પશુ ઘેર ।।૫૫।।',
        '<b>ઇતિ શ્રીમદેકાન્તિકધર્મપ્રવર્તક શ્રીસહજાનંદ સ્વામિ શિષ્ય નિષ્કુળાનંદ મુનિ વિરચિતે ભક્તચિંતામણી મધ્યે શ્રીહરિ ચરિત્ર નામે એકત્રીશમું પ્રકરણમ્ ।।૩૧।।</b>',
      ],
    },
    {
      name: 'ભક્તચિંતામણી પ્રકરણ ૩૨',
      content: [
        '<b>પૂર્વછાયો-</b>',
        'શુભમતિ સહુ સાંભળો, હરિકથા કહું અનૂપ । \nદુષ્ટને દુઃખદાયી છે, છે સંતને સુખરૂપ ।।૧।।',
        'અસુર જે અવનિ રહ્યા, બદલાવીને બીજો વેશ । \nતેને તે અર્થે શ્રીહરિ, ફરે છે દેશ પ્રદેશ ।।૨।।',
        'જે કારણ અવતાર છે, તે કરવા થયા છે તૈયાર । \nહરિ ઇચ્છાએ આવિયા, પ્રભુ એહ વાડી મોઝાર ।।૩।।',
        'પિબૈક ત્યાં પરિયાંણિયો, કરવા તે સિદ્ધોની ઘાત । \nનિદાન તેમાંથી જે નિપજ્યું, તેની સાંભળજ્યો સહુ વાત ।।૪।।',
        '<b>ચોપાઇ-</b>',
        'હતો બ્રાહ્મણ મોરે એ શુદ્ધ, મળી વામિએ કર્યો અશુદ્ધ । \nકૌલાવર્ણવે ભણેલે ભુદેવે, મળી ભષ્ટ કર્યો તતખેવે ।।૫।।',
        'થયો કાલી ઉપાસક ભારી, નિત્ય પ્રત્યે પિવે કુળવારી । \nવળી સિદ્ધને જીતવા કાજ, તેદિ સજ્યો સરવે સમાજ ।।૬।।',
        'મદ્ય માંસ ખાઇ થયો મસ્ત, તિખું ત્રિશુળ લીધું છે હસ્ત । \nકર્યો સિંદૂરલેપ લલાટે, ચાલ્યો સિદ્ધને જીતવા માટે ।।૭।।',
        'રજે ભિંજેલ કુંકુમ લઇ, ચાલ્યો કપાળે ચાંદલો દઇ । \nવળી કુળવારી ખુબ પીધું, વધ્યું તે શરીરે છાંટી દીધું ।।૮।।',
        'ચાલ્યો મત્સ્ય ચાવી મદમાતો, શિશે બાંધ્યો છે પટકો રાતો । \nમાથે ઘણા ઘુંચાળા મુવાળા, તે દિસે છે ભૂંડા ભમરાળા ।।૯।।',
        'સિદ્ધ પ્રસિદ્ધ પોતે કહેવાય, તેને જાણે સહુ દેશમાંય । \nકોઇ સામું આવીને ન ભાખે, ભાખે જો કોઇ તો મારી નાખે ।।૧૦।।',
        'એવો ભૂંડાઈએ ભર્યો અપાર, મદ્ય ચાર વારનો પિનાર । \nકંઠે બાંધ્યાં છે અસ્થિ માંજાર, થયો સિદ્ધ જીતવા તૈયાર ।।૧૧।।',
        'રનારી જે રહે ગામવાસે, સંગે લઇ આવ્યો સિદ્ધને પાસે । \nવળતો આવીને બોલિયો એમ, પાખંડિયો સિદ્ધ કાવો કેમ ।।૧૨।।',
        'સિદ્ધ તો એક હું છઉં આજ, તમ જેવા તો છે મારું ખાજ । \nમોટામોટાને મેં જીતી લીધા, તમ જેવા શિષ્ય કઇ કીધા ।।૧૩।।',
        'જેજે આવીને મને નમિયા, તેતે સરવે જીવતા રિયા । \nજેણે જેણે બાંધી મુજ સાથે, તેને માર્યા મુકી વીર માથે ।।૧૪।।',
        'માટે તમે મનમાં વિચારી, થાઓ શિષ્ય માળાઓ ઉતારી । \nઉતારો ઉપવીત અચિર, નહિ તો હમણાં હોકારું છું વીર ।।૧૫।।',
        'ભૂત પ્રેત લાવી સંગે ઘણાં, ખાઇ જાશે માંસ તમતણાં । \nએમ બોલ્યો એ બળમાં બહુ, બિના હરિ વિના સિદ્ધ સહુ ।।૧૬।।',
        'કહે જેમ એ કહે તેમ કરીએ, તો આવ્યા મોતમાંથી ઉગરીએ । \nનહિ તો મારશે વીરને મેલી, માટે મેલીએ માળા સંકેલી ।।૧૭।।',
        'મેલો જનોઇ પણ ઉતારી, એમ સહુ સિદ્ધે વાત વિચારી । \nત્યારે હરિએ સિદ્ધ પ્રત્યે કહ્યું, રાખો ધીરજે હાકલી હૈયું ।।૧૮।।',
        'કરે શિષ્ય મોરે મર મને, પછી થાવું તમારે સહુને । \nતોયે સિદ્ધે ધીરજ ન ધારી, કહ્યું નાખશે તમને મારી ।।૧૯।।',
        'મહા દુષ્ટ એ પાપી છે બહુ, એને અમે જાણું છઉં સહુ । \nએવી સિદ્ધ હરિની જે વાણી, સુણી બોલ્યો અતિ ક્રોધ આણી ।।૨૦।।',
        'ત્યારે પિબૈક કહે બ્રહ્મચારી, તને દેખાડું સામર્થી મારી । \nજો તું આ નીલા વડના હાલ, હમણાં કરુંછું સુકવી સાલ ।।૨૧।।',
        'એમ કહી નાખી મુઠ જ્યારે, વડ સુકી ગયો તેહ વારે । \nકહે માન્ય વર્ણી વાત મારી, નહીં તો આ ગતિ જાણજે તારી ।।૨૨।।',
        'ત્યારે હરિ કહે ન બિયું અમે, કરવું હોય તે કરો સુખે તમે । \nએમ કહીને વીરઆસને, બેઠા હરિ તે અચળ મને ।।૨૩।।',
        'બીજાની તો ધીરજ ન રઇ, બેઠા કૃષ્ણ કેડે કંપે જઇ । \nપછી પિબૈકે અડદ મંતરી, નાખ્યા હરિ ઉપર રીશ કરી ।।૨૪।। ',
        'તેણે હરિને ન થયું કાંઇ, ત્યારે દ્વિજ કોપ્યો મનમાંઇ । \nકહે રહેજે ખબરદાર થઇ, આજ માર્યા વિના મુકું નઇ ।।૨૫।।',
        'એમ કહિ નાખી મુઠ એણે, ફેર પડયો નહિ કોઇ તેણે । \nત્યારે પિબૈકે પણ એ લીધું, તને માર્યાનું નિશ્ચય મેં કીધું ।।૨૬।।',
        'કરવું હોય તે કર સ્મરણ, આજ આવ્યું તારું ચાલી મરણ । \nનાખું છું કાળભૈરવની મુઠું, તારા જીવવાનું જાણે જુઠું ।।૨૭।।',
        'ત્યારે હરિ કહે બેઠો છું હું જ, કરવું હોય તે કરને તું જ । \nત્યારે મુક્યા છે ભૈરવ વીર, તોય હરિ બેઠા રહ્યા સ્થિર ।।૨૮।।',
        'આવ્યા ભૈરવ ને વીર દોઇ, તેતો સામું શકયા નહિ જોઇ । \nપાછા પિબૈક ઉપર પડયા, ઉલટા નાખતલને નડયા ।।૨૯।। ',
        'પડયો કાળી ઉપાસક ઢળી, ચાલ્યું મુખેથી લોહી નિકળી । \nઆવી મૂરછા ન રહી શુદ્ધ, પડયો અવનીએ ઉંદ્ધમુધ્ધ ।।૩૦।।',
        'પછી મોડેથી મૂરછા વળી, ઉઠી બોલ્યો છે બળમાં વળી । \nકહ્યું ઉભો રહેજે બ્રહ્મચારી, મેલું બટુભૈરવ નાખે મારી ।।૩૧।।',
        'ત્યારે હરિ કહે મોકલો સુખે, મુક્યા બટુવીરને વિમુખે । \nતેતો બિને પાછા વળી ગયા, પાછા પિબૈકને વળગિયા ।।૩૨।।',
        'નાખ્યો ભૂમિયે પાડી પડાક, ધ્રુજી ધરણીએ પડયો ધડાક । \nવળી તડિ તડફડિ ઉઠયો, બોલ્યો પ્રભુજી ઉપર રૂઠયો ।।૩૩।।',
        'કહે મુકું છું વીર મહાકાળી, તને નહિ મારે બીજાં એટાળી । \nએમ કહીને તેને મુકીયાં, તેતો હરિ પાસે ન આવિયાં ।।૩૪।। ',
        'પાછા ફરીને લાગિયાં એને, ઢાળી પાડિયો ભૂમિએ તેને । \nથયો અસોયો ન રહી શુદ્ધ, તોય વામી ન મુકે વિરુદ્ધ ।।૩૫।।',
        'પડી પહોર ઉઠી ઉભો થયો, વળી પ્રભુજીને કહેવા રહ્યો । \nકહે છે ઉભો રહે જે બ્રહ્મચારી, હવે કરું છું વલે હું તારી ।।૩૬।।',
        'બહુ વીર સહિત હનુમંત, મુકું તેને કરે તારો અંત । \nએમ કહીને મુક્યા તે વાર, આવી તેણે કર્યો નમસ્કાર ।।૩૭।।',
        'કરી પ્રણિપત પાછા ગીયા, બહુ પિબૈક પર કોપિયા । \nઆવી વળગ્યા તે વિપ્રને સહુ, પડયો વિપ્ર ભૂંડે હાલ બહુ ।।૩૮।।',
        'ફાટયું મોઢું ને આવિયું ફીણ, પડી અંગની નાડિયો ક્ષીણ । \nમાથું ગરી ગયું મહી માંઇ, મુખમાં ગઇ ધુડય ભરાઇ ।।૩૯।।',
        'પડયો ભેચક થઇ રીત્ય ભૂંડી, આંખ્યો ઉતરી ગઇ છે ઉંડી । \nનાક મુખમાંથી લોહી વહ્યું, પછી ઉઠવા જેવું ન રહ્યું ।।૪૦।।',
        'ત્યારે તેના સંબંધી સહુ મળી, લાગ્યાં પાય પ્રભુજીને લળી । \nકહે દયા કરો એને હરિ, હવે નહિ કરે એ આવું ફરી ।।૪૧।।',
        'માગતો હતો તે ફળ મળ્યું, મહા અહંકારીનું માન ગળ્યું । \nપછી પ્રભુએ તેને ઉઠાડયો, ઉઠી વિપ્ર પ્રભુ પગે પડયો ।।૪૨।।',
        'કર્યા દંડવત બહુ વાર, કહે આવ્યો નવે અવતાર । \nપછી સિદ્ધ હતો તેને જોઇ, આપી બ્રાહ્મણે તેને રસોઇ ।।૪૩।।',
        'એમ કરી ગયો ઘેર જ્યારે, મન રહ્યું નહિ એવું ત્યારે । \nપૂજ્યો કાળભૈરવને જઇ, મદ્ય માંસ બલિદાન દઇ ।।૪૪।। ',
        'મુક્યો હરિ માથે તતકાળ, આવ્યો ભયંકર વિકરાળ । \nભૂંડું મુખ તે ભર્યું રુધિરે, લાંબો ને નથી વસ્ત્ર શરીરે ।।૪૫।।',
        'આંખ્યું રાતી અતિ કાળો શાહી, લીધું ત્રિશુળ તે કરમાંહિ । \nએવે રૂપે પ્રભુ પાસે આવ્યો, પણ આવિને કાંઇ ન ફાવ્યો ।।૪૬।।',
        'છેટે બેસી રહ્યો આખી રાત્ય, હરિ હસ્યા જોઇ પરભાત્ય । \nપછી નાવા ચાલ્યા જ્યારે હરિ, ત્યારે એના સામી દ્રષ્ટિ કરી ।।૪૭।।',
        'ત્યારે થરથર ધ્રુજીને ભાગ્યો, જઇ પિબૈકને કેડે લાગ્યો । \nકહે આજ નિશ્ચે એને મારું, ત્યારે પ્રભુજીએ કર્યું વારું ।।૪૮।।',
        'કહે એનું ખાધું સિધ્ધે અન્ન, તેની તારે કરવી જતન । \nપછી બ્રાહ્મણ પાસે ભૈરવ, જઇ કહી છે વાત સરવ ।।૪૯।। ',
        'આજ મૃત્યું કરવું તું તારું, પણ વર્ણિએ કર્યું છે વારું । \nએમ કહીને ભૈરવ ગીયો, દ્વિજ પ્રભુને પાસે આવિયો ।।૫૦।।',
        'જાણી ઇશ્વર નામિયું શિશ, કહે કરજ્યો ગુના બકશિશ । \nએમ કહીને વારમવાર, કરે બહુ પોતાને ધિક્કાર ।।૫૧।।',
        'કહે ભણીગણી ભજ્યાં ભૂત, કર્યાં બહુ ભૂંડાં કરતુત । \nકર્યાં કુકર્મ તજી આચાર, એવો પાપી હું તેને ધિક્કાર ।।૫૨।।',
        'એમ કહી ગ્રહ્યું હરિચરણ, પ્રભુ આવ્યો હું તમારે શરણ । \nએમ કહીને થયો છે શિષ્ય, જાણી મહારાજને જગદીશ ।।૫૩।।',
        'ધાર્યો પ્રથમના જેવો ધર્મ, મુક્યાં બીજાં જે કરવાં કુકર્મ । \nકૌલાર્ણવાદિ ગ્રંથ સંભાળી, શાસ્ત્રબાહ્ય જાણી દીધા બાળી ।।૫૪।। ',
        'ભાગવત ગીતા પછી ભણ્યો, સાચો ભક્ત શ્રીકૃષ્ણનો બણ્યો । \nએવું ચરિત્ર કરી દયાળ, પછી ત્યાંથી ચાલ્યા તતકાળ ।।૫૫।।',
        'હતા સિદ્ધ તેને શીખ દીધી, પોતે વાટ નવલખાની લીધી । \nમનુષ્યાકૃતિ સામર્થી અપાર, ધન્ય જનમોદન ભંડાર ।।૫૬।।',
        '<b>ઇતિ શ્રીમદેકાન્તિકધર્મપ્રવર્તક શ્રીસહજાનંદસ્વામિ શિષ્ય નિષ્કુળાનંદ મુનિ વિરચિતે ભક્તચિંતામણી મધ્યે શ્રીહરિ ચરિત્ર નામે બત્રિશમું પ્રકરણમ્ ।।૩૨।।</b>',
      ],
    },
    {
      name: 'ભક્તચિંતામણી પ્રકરણ ૩૩',
      content: [
        '<b>પૂર્વછાયો-</b>',
        'બહુ નામી કૃષ્ણદેવનાં, સુણો સર્વે ચરિત્ર રસાળ । \nભક્ત અભક્ત કારણે, ફરે હદ્ય બેહદ્યે દયાળ ।।૧।।',
        'સંતને સુખ આપવા, દેવા દુષ્ટજનને દંડ । \nતેજ કારણ પ્રભુએ, પૃથિવીએ ધર્યું છે પંડ ।।૨।।',
        'જીયાં જીયાં જાવું ઘટે, તિયાં તિયાં જાય જરૂર । \nસુખ દુઃખ ભૂખ પ્યાસનું, નથી ગણતા નિકટ દૂર ।।૩।।',
        'પછી એકા એક ચાલિયા, મહાવિકટ અદ્રિમાંય । \nનવ લાખ યોગીને નિરખવા, ચાલ્યા પ્રભુજી બહુ ઉછાય ।।૪।।',
        '<b>ચોપાઇ-</b>',
        'ચાલ્યા પર્વત પેખવા હરિ, આવ્યો અતિવસમો અદરિ । \nજાવા વાટ જડે નહિ જીયાં, જાવું જરૂર પોતાને તિયાં ।।૫।।',
        'જાતાં એ દિશે મનુષ્યે વાર્યું, પણ કર્યું છે પોતાનું ધાર્યું । \nચડયા પર્વત ઉપર પોતે, ચાલ્યા શૈલતણી શોભા જોતે ।।૬।।',
        'આવી નવલાખ ધુણી રૂપાળી, તે તો સહેજે બળે વણબાળી । \nતિયાં કુંડ દિઠા છે અપાર, ભર્યાં જળ અમળ તે મોઝાર ।।૭।।',
        'ક્યાંક ટાઢાં જળ ક્યાંક ઉનાં, એમ બહુ કુંડ દીઠા નીરુના । \nપછી ત્યાંના રહેનારા જે સિદ્ધ, મળ્યા તે પણ આવી પ્રસિદ્ધ ।।૮।।',
        'જેની કોઇને ભેટ ન હોય, મર મોટા મુક્તયોગી હોય । \nતેને સહુને મળ્યા એકવાર, કર્યા પરસ્પર નમસ્કાર ।।૯।।',
        'પછી પ્રેમે બેઠા સહુ પાસ, પુછયો યોગીને યોગ અભ્યાસ । \nકહ્યું તેણે તેનું વરતંત, સુણી હરિ હરખ્યા અત્યંત ।।૧૦।।',
        'કહ્યું સિદ્ધને છો ધન્ય ધન્ય, એમ કહી રહ્યા ત્રણ્ય દન । \nઆપી આનંદ ત્યાંથી ઉતર્યા, ચાલ્યા હરિ બહુ મોદ ભર્યા ।।૧૧।।',
        'નવ લાખ યોગીને નિરખી, ચાલ્યા હસ્તિ મારગે હરખી । \nત્યાંથી શુદ્ધ ઉત્તરમાં વળી, રહ્યો રામકોટ જળમળી ।।૧૨।।',
        'તિયાં જાવાનું કીધું છે મન, પણ ત્યાંતો ન ગયા જીવન । \nજોતા વન પર્વત વિશાળ, ગયા બાલવા કુંડે દયાળ ।।૧૩।।',
        'જોયો કુંડ એ જુગતે કરી, ત્રણ્ય રીત્યને રહ્યો તે ધરી । \nવાયુ અગ્નિ જળ ત્રણ્ય મળી, તેના સમોહ રહ્યા નિકળી ।।૧૪।। ',
        'તિયાં રહ્યા પોતે ત્રણ્ય દન, પછી ત્યાંથી ચાલ્યા ભગવન । \nકર્યો અગ્નિ ખુણે પરવેશ, ચાલ્યા જોતા જોતા સર્વે દેશ ।।૧૫।।',
        'ગયા ગંગા સિંધુને સંગમે, નાયા તેમાં પોતે જઇ સમે । \nરહ્યા ત્રણ્ય દિવસ તિયાં હરિ, ચાલ્યા સમુદ્ર ખાડી ઉતરી ।।૧૬।।',
        'આવ્યા કપિલજીને આશ્રમે, જેહ જાયગા સહુને ગમે । \nચ્યારે કોરે શોભે સમુદર, મધ્યે આશ્રમ અતિ સુંદર ।।૧૭।।',
        'સાંખ્યશાસ્ત્રના આચાર્ય જેહ, તેના ગુરુ કપિલજી તેહ । \nકરે છે તપ પોતે સમર્થ, સર્વે જીવના કલ્યાણ અર્થ ।।૧૮।।',
        'જ્ઞાન વૈરાગ્ય ભક્તિ ને ધર્મ, યોગસહિત પાંચ જે પર્મ । \nતેના સ્થાપનના કરનાર, એવા કપિલનાં કર્યાં દેદાર ।।૧૯।।',
        'રહ્યા ભક્તિ સુત તિયાં માસ, પછી ત્યાંથી ચાલ્યા અવિનાશ । \nઆવ્યા પુરુષોત્તમ પુરીમાંઇ, નિર્ખ્યા જગન્નાથજીને ત્યાંઇ ।।૨૦।।',
        'પછી રહ્યા પોતે એહ ઠામ, કાંયેક ધાર્યું છે કરવું કામ । \nનાય સમુદ્રમાં જઇ નિત્યે, નિરખી જગન્નાથજીને પ્રીત્યે ।।૨૧।। ',
        'કર્યું આસન શહેરથી બારું, ઇંદ્રદ્યુમ્ન સર જોઇ સારું । \nતિયાં દીઠા છે અસુર ઘણા, વેષ લઇ સિદ્ધ સાધુ તણા ।।૨૨।।',
        'કામ ક્રોધ ને મત્સર અતિ, માંહોમાંહિ છે વૈરની મતિ । \nધર્મદ્વેષી કપટી ને કામી, વેષ શૈવા વૈષ્ણવી ને વામી ।।૨૩।।',
        'મંત્ર જંત્ર જાણે છે અપાર, તેણે વશ કર્યાં નરનાર । \nમુકાવી વર્ણાશ્રમનો ધર્મ, કર્યાં ભ્રષ્ટ બગાડી બેશર્મ ।।૨૪।। ',
        'મોટા મોટા સાધુ સેવા ફળ, કહી દેખાડે નારી આગળ । \nએમ કહીને ધર્મથી પાડે, સાધુનિંદાનું પાપ દેખાડે ।।૨૫।।',
        'એવા અધર્મે ભર્યા અપાર, દીઠા હરિએ હજારો હજાર । \nધર્યાં હાથે તિખાં હથિયાર, લીધા ધોકા છરા ને કટાર ।।૨૬।।',
        'ખડગ ખાંડાં લાકડી લુવાગ્યું, કર કમાન્યું સલકે સાંગ્યું । \nબહુ બંધુકું ને કોક બાણ, ચક્ર ચિપિયા લીધા છે પાણ ।।૨૭।।',
        'પર્શુ ત્રિશુળ બરછિયો લઇ, જંજાળ્યો આદ્યે શસ્ત્ર છે કઇ । \nઅતિશૂરા ઇચ્છે નિત્ય યુદ્ધ, કેટલાક ન રાખે આયુધ ।।૨૮।।',
        'કેટલાક ત્યાગી તપ કરતા, કેટલાક સૌમ્ય વેશ ધરતા । \nકૌલાર્ણવ ગ્રંથ વાંચે નિત્યે, પૂજે શક્તિ ભૈરવને પ્રીત્યે ।।૨૯।। ',
        'એવા દીઠા છે નાથે અપાર, મહા પાપરૂપ ભૂમિ ભાર । \nતિયાં રહ્યા પોતે અવિનાશ, કરવા એવા અસુરનો નાશ ।।૩૦।।',
        'પણ પોતાથી ન થાય રતિ, કાંજે રાખે છે અહિંસાવૃત્તિ । \nબેસી રહ્યા વિચારી એ વિધ, ત્યારે લોકે જાણ્યા છે આ સિદ્ધ ।।૩૧।।',
        'એમ જાણીને પુછે છે જેહ, કહે હરિ થાય તેમ તેહ । \nત્યારે લોકને આવી પ્રતીત્ય, લાવે અન્ન વસ્ત્ર દ્રવ્ય નિત્ય ।।૩૨।।',
        'તેહ માંયલું કાંય ન લીયે, ભૂત ભવિષ્યનું કહી દિયે । \nએમ કરતાં તે એક દન, આવ્યા પાસળે અસુર જન ।।૩૩।।',
        'કહે કર અમારું તું કાજ, લાવ્ય જળ ઇંધણાં સમાજ । \nદઇ ડારો ને બહુ ડરાવ્યા, ન કરવાનાં કામ કરાવ્યાં ।।૩૪।। ',
        'કરે કામ જડભરત જેમ, ત્યારે બીજા કે મ કરો એમ । \nતૈયે તેશું બોલ્યા ક્રોધ કરી, એનો પક્ષ મેલો પરહરી ।।૩૫।।',
        'એમ વદતાં પડયો વિરોધ, માંહોમાંહિ ઉપન્યો કરોધ । \nપછી તેમાં પડયાં તડ બેહુ, નિંદા પરસ્પર કરે તેહુ ।।૩૬।।',
        'એમ કરતાં બંધાણું વેર, ઇચ્છયા મારવા નહિ જેને મેર । \nપ્રથમ તો બોલી ઠોલી થઇ, પછી ઉઠયા લઠા મોટા લઇ ।।૩૭।।',
        'લીધી લડવા પહેલી લાકડી, તેતો પરસ્પર બહુ પડી । \nપછી લીધાં ખાંડાં કરમાંય, આવ્યા સામસામા મળી ધાય ।।૩૮।।',
        'નાખે બર્છિયો બહુ સામસામી, ચાલે લુવાગ્યું ન રહે ખામી । \nસલકે સાંગ્ય ને સણેણે તીર, લડે માંહોમાંહે શૂરવીર ।।૩૯।। ',
        'કરે પર્શુના બહુ પ્રહાર, નાખે ધોકા છરા ને કટાર । \nતીખાં ત્રિશૂળ ચાલે ત્યાં ઘણાં, કરે ઘા ચક્ર ચિપિયા તણા ।।૪૦।।',
        'બંધુકો જંજાળ્યો કોકબાણ, નાખે માંહોમાંહે અસુરાણ । \nએમ જોરે મંડાણું છે યુદ્ધ, પામ્યા ત્રાસ આકાશે વિબુધ ।।૪૧।।',
        'વાજે ઢોલ નગારાં જુજાર્યો, ચાલે સામસામી તરવાર્યો । \nતુરી રણશિંગાં બોલે શંખ, પડે પાપીનાં માથાં અસંખ્ય ।।૪૨।।',
        'મચ્યો યુદ્ધ રહી નહિ મણા, પડયાં પૃથિવીએ ધડ ઘણાં । \nતેને દેખી હરખ્યાં માંસારી, કહે ખાશું આજ ખુબ કરી ।।૪૩।।',
        'ભૂત પ્રેત ને આવ્યા ભૈરવ, પિશાચ યક્ષ રાક્ષસ સર્વ । \nડાકણી સાકણી ને જોગણી, આવી ભૂખી ભૈરવીયો ઘણી ।।૪૪।। ',
        'કંક કાગ ને વળી કુતરા, થઇ ગૃધ્ર શિયાળ ને સરા । \nપડયાં માથાં પૃથ્વી પર રડે, જાણ્યું દૈત્ય રમી ગયા દડે ।।૪૫।।',
        'કૈકના હાથ પગ કપાણા, કૈક નાશી ભાગીને છપાણા । \nએમ યુદ્ધ થયું બહુ પેર, દશસહસ્ર ગયા યમ ઘેર ।।૪૬।।',
        'એટલા તો ઇયાંતળ રહ્યા, બીજા નાશી ભાગી પણ ગયા । \nતેતો દેશ પ્રદેશે પ્રવરી, બીજા દૈત્યો આગે વાત કરી ।।૪૭।।',
        'કહ્યું એક હતો નાનો બાળ, તેને જાણતા અમે દયાળ । \nતેને અર્થે વધિયો વિરોધ, મુવા માંહોમાંહિ કરી ક્રોધ ।।૪૮।।',
        'ત્યારે અસુર બોલિયા એમ, એને ઓળખીયે અમે કેમ । \nત્યારે કહ્યું આપીએ એંધાણ, તેણે પડે તમને પેછાણ ।।૪૯।। ',
        'અતિ ત્યાગી તપસ્વી છે તને, નથી લોભાતો નારી ને ધને । \nરહે છે સમાધિમાં દિન રાત, વળી જાણે છે મનની વાત ।।૫૦।।',
        'એથી પડયો પરસ્પર ભેદ, થયો તેણે આપણો ઉચ્છેદ । \nમાટે મળે તો મેલવો નહિ, એવી વાત અસુરને કહિ ।।૫૧।।',
        'એવું સુંણીને બોલ્યા અસુર, હવે ગોતશું એને જરૂર । \nજો મળશે તો મારશું છળે, એમ બંધાણું વેર સઘળે ।।૫૨।।',
        'એમ દૈત્યે કર્યું પરિયાણ, તેને જાણે છે હરિ સુજાણ । \nકહે છે જેમ થશે તેમ ઠીક, નથી આત્માને કેની બીક ।।૫૩।।',
        'એમ કહી જોયું તતખેવ, દીઠા દૈવી સંપત્તિના જીવ । \nતેને પોતે ઉપદેશ આપી, કર્યા સુખી ભવદુઃખ કાપી ।।૫૪।। ',
        'આ જે હરિચરિત્ર અનૂપ, કૃષ્ણભક્તને છે સુખરૂપ । \nતેને કહેશે સાંભળશે જેહ, મહાકષ્ટથી મુકાશે તેહ ।।૫૫।।',
        'આલોકમાં પણ સુખી રહેશે, પરલોકે પરમ સુખ લેશે । \nપાપહરણી કથા છે પવિત્ર, જેમાં પ્રગટ પ્રભુનાં ચરિત્ર ।।૫૬।।',
        'ભક્ત હશે તે સુણશે ભાવે, અભક્તને અર્થ નહી આવે । \nકહેશે હેતે સાંભળશે કાન, તે પર રાજી થાશે ભગવાન ।।૫૭।।',
        '<b>ઇતિ શ્રીમદેકાન્તિકધર્મપ્રવર્તક શ્રીસહજાનંદ સ્વામિ શિષ્ય નિષ્કુળાનંદમુનિ વિરચિતે ભક્તચિંતામણી મધ્યે શ્રીહરિ ચરિત્ર નામે તેત્રિશમું પ્રકરણમ્ ।।૩૩।।</b>',
      ],
    },
  ],
};

export default niyam;
