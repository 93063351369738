import { NiyamConfig } from '../react-i18next';

const niyam: NiyamConfig = {
  name: 'Shanti Paath',
  tabs: [
    {
      name: 'Bhaktachintāmaņi Prakaran 29',
      content: [
        '<b>Hari - Tapashcharyā - Varnan</b>',
        '<b>Pūrvachhāyo</b>',
        'Tyār pachhīnī je vāratā, tame sambhaļo sahu sujān; \n Tyāg vairāgya je Nāthno, tenā shiyā karu hu vakhān. 1',
        'Bhūlye pan nij dehne, māne nahi koi din; \n Ishvar ichchhāe tan rahyu, pan pote na karyu jatana. 2',
        'Pachhi Prabhuji bethā hatā, geherī Gangāne tir; \n Tyāthī ūthī ūtariyā, je hatu athāha nira. 3',
        'Uttar-dishamā chālavā, ati antarmā chhe ānand; \n Zara nagne nizarane, chālya Ghanashyām sukhakand. 4',
        '<b>Chopāi</b>',
        'Chālyā uttar dishe dayāl, nidhadak thai tatakāļ; \n Motā motā parvat be pāse, jānu adri adyā chhe ākāshe. 5',
        'Sām sāmi zūki chhe shikharyo, bahu biyāmani ghani zaryo; \n Chāle nizarane nir ghanā, thāy ghosh akhand te tanā. 6',
        'Jem paraspar zūkyā pahād, tem zakumbī rahyā chhe zād; \n Namī rahyu karādyu karāļ, temā chālyā jāy chhe dayāl. 7',
        'Sāmī nadie chālyā сhhe Shyām, je koi sarvenā ātamārām; \n tyā to ādo āvyo chhe achaļ, āve tenī gufāmāthī jaļ. 8',
        'Vahe vegmā dhārā prachand, thāy ghosh tehno akhand; \n Tranye kore jāvā nahi jāgya, vaļe kem jene chhe vairāgya. 9',
        'Pachhi betha tiya Ghanashyām, din asta pāmyo eha thām; \n Vaļatu joyu pūrve vilokī, dītho purush tyā ek alokī. 10',
        'Tene van-pūchhe kahi vāt, kiyā jāvu chhe he Jagatāt; \n Pachhi Hari boliyā chhe tyai, māre jāvu chhe uttar mai. 11',
        'Pan tame kon chho dayāļ, āvyā āne same tatakāļ; \n Tyāre te kahe suno Bhagawān, Himāchaļ hu mūratimān. 12',
        'Em kahine batāvī vāt, garo gufāmā Varanirāt; \n Emā chalatā āvashe mag, em kahine na dekhāno nag. 13',
        'Pachhī chālyā emā avināsh, jene nathi dehno adhyās; \n Pethā ghor andhāri gufāmā, āvati jaladhārāne sāmā. 14',
        'Chālatā chālatā vityo pahor, bijānu kem haiyu rahe tor; \n Māhi motā motā manidhar, krūma krachalā karī rahyā ghar. 15',
        'Min magharyo dādur jemā, nihshank mane chālyā jāy temā; \n Pachhī pamiyā ehno pār, nisaryā ghor gufāne bār. 16',
        'Tyā to āviyo chhe ek dhroh, undo athāh jantusamoh; \n Vityā tran divas jo tyāi, faļ ful malyu nahi kāi. 17',
        'Bhukhyā padi rahyā tiyā rātya, shu kahu e dhīrajanī vāt; \n Sutā nihshank thai gai ren, jāgyā prabhāte kamaļanen. 18',
        'Nāhī sandhyā karī tehvār, malyā faļ ful karyo āhār; \n Pachhi chālyā tyāthi thodu ghanu, malyu edhān māragatanu. 19',
        'Tyāre chālyā e mārag lai, tiyā din vītī gayā kai; \n Pachhī Pulah Brahmānā sutan, āvyu āshram tenu pāvan. 20',
        'Ati chamatakārī chhe eh, thāy sukhī seve jan jeh; \n Tapfaļ maļe tiyā tarata, jiyā tap karyu āge Bharat. 21',
        'Mumukshune chhe sevavā jevu, jyā Shrī Krushnane chhe nitya rahevu; \n Chakra nadī jīyā chāre kor, nāyā chhe temā Dharmakishor. 22',
        'Karī kriyā tyā pāth pūjan, karyu Muktanāthnu darshan; \n Pachhi Bharate karyu tap jīya, pote pan bethā jai tiyā. 23',
        'Tenī pethye ādaryu chhe tap, tenājevo kare nitya jap; \n Jānyu pāmyā Bharat mrugdeh, māte pote rahe chhe nispreh. 24',
        'Em sang tajyo jyāre bār, pachhī antare karyo vichār; \n Puranjannī kathā sambhārī, mūkyo buddhino sang visārī. 25',
        'Shuddha swarup ātmā kahevāy, potātanu mānyu chhe temāy; \n Pachhī urdhva bāhu karī āp, kare Gāyatrīno nitya jāp. 26',
        'Dharyu Sūrya Nārāyannu dhyān, Gandkīmā kari nitya snān; \n Muktanāth sevā man game, faļ ful je maļe te jame. 27',
        'Tenejoī tapī tyā rahenār, vismay pāme man mozār; \n Kahe āto Prahlād chhe āpe, kāto dhrune mūkyā farī bāpe. 28',
        'Kā to Swāmī Kārtik kahie, kā to Sanatakumār lahīe; \n Kā to Sanakādik sujān, kā to Dattātrey paramān. 29',
        'Rubhu kā to Nārāyan Rushi, teh vinā nahī ā tapasī; \n Jone kathan kare tap tane, toy padatā nathi moļā mane. 30',
        'Juvo sukh shobhā muni Indra, jānu pūramāsīno chandra; \n Avā Brahma Srushtimā na bhāļyā, te to aj najare nihāļyā. 31',
        'Motu tap manushye na thāy, te ādaryu chhe ati uchhāy; \n Ene āgaļ tap āpanu, thayu khelavanu bāl tanu. 32',
        'Dise bal pan motā bahu, joī vismay pāmya chhie sahu; \n Em māho māhi kahe muni, joi tapasā teh Prabhunī. 33',
        'Evu tap joī bījājan, Prabhu dukhe dukhānā chhe man; \n Bhakti-Dharma divya deh dharī, rahe chhe pāse doy hete karī. 34',
        'Ati krush ubhā ek page, nathī dekhī shakatā te drage; \n Jānyu ladathadi padashe nāth, khamā khamā kahi diye hāth. 35',
        'Ati het chhe harine māthe, tene dampati rahe chhe sāthe; \n Deh durbaļ dekhyu najāy, rati rudhir nahi tan māy. 36',
        'Ango-angnī je sarve nādī, te to thai rahī chhe ughādī; \n Dise asthi āmish na dise, tvachā chotī rahi chhe te vishe. 37',
        'Deh jotā deh navya rahe, em jan joi sahu kahe; \n Eve sharīre ādaryu tap, kare chhe mukhe Gāyatrī jap. 38',
        'Temā dhare chhe Sūryanu dhyān, bhāve karī pote Bhagawān; \n Kare chhe evu tap hammesh, devā tapasvīne upadesh. 39',
        'Em tap karyu mās chār, sahī megh tanī ghanī dhār; \n Karīi temā upāsanā ghanī, te to Sūryanārāyan tanī. 40',
        'Em karatā Ekādashī jeh, āvī Prabodhinī nāme teh; \n Tyāre ati ānand vadhārī, karyujāgaran jāminī sārī. 41',
        'Tyāre Sūryanārāyane tyāy, dīdhu darshan e nishi māy; \n Ati sundar tan swarūp, ango-ange shobhe chhe anūp. 42',
        'Doy kar kamaļ chhe hāthe, nangjadit mugat māthe; \n Kanak kadā chhe karmā kāju, doy bhujāe bāndhyā chhe bāju. 43',
        'Kāne Kundaļ shobhe chhe sār, tej tej tejnā ambār; \n Hāsye sahit shobhe vadan, temā karunāe bharyā lochan. 44',
        'Evā Sūrye dīdhā darashan, ati āpe thaīne prasanna; \n Tene dekhī ūthyā Hari tarat, karyā bhaktie shu dandavat. 45',
        'Thayā gadgad kanthe Varnī, preme bhūlyā shūdha tan-tanī; \n ākhye āsu romāchit tan, jodī hāth kare chhe stavan. 46',
        'Tame tej-punja māratand, nij teje prakāsho brahmand; \n Dharī Kashyap gher avatār, evā tame tene namaskār. 47',
        'Tamāre ugave karī dayāļu, thāy sarve jagat sukhāļu; \n Temā pāpī pīdāye apār, evā tame tene namaskār. 48',
        'Tamāre ugave karī kahu, kare kāļnī gananā sahu; \n Nīkar noy kāī niradhār, evā tame tene namaskār. 49',
        'Tamāre ugave karī āp, sarve prāṇīnā bāndhyā chhe māp; \n Dev dānav ne nar-nāra, evā tame tene namaskār. 50',
        'Tame prabhu pragat chho dev, sahu jan jāne chhe e bhev; \n Nathi chhāno pratāp lagār, evā tame tene namaskār. 51',
        'Evi stuti karī jodī kar, tyāre bhāve bolya Bhāsakar; \n Māgo Hari mu pāsethi āj, tyāre Hari kahe māgu Mahārāj. 52',
        'Kām krodh dambh lobh moh, indri gun ādi je samoh; \n Tethī rakshā karajyo amārī, jene rahīe naishtik brahmachārī. 53',
        'Vaļī jyāre jyāre hu sambhāru, tyāre darshan thāy tamāru; \n Eja var māgu chhu tamathī, māyik sukh māgato nathī. 54',
        'Tyāre Sūrya kahe sukhadāī, tamvade mārī chhe motāi; \n Avo tej pratāp chhe māro, te to sarve jāno chhe tamāro. 55',
        'Nathi samarth tamthi ame, pan thāshe je māgyu chhe tame; \n Em Harine āpī varadān, pote thayā pachhi aritardhān. 56',
        'Pachhī tap samāpati karī, eh kshetra vakhāne chhe Hari; \n Rahyā tiyā dvādashīno dan, pachhi karyu chhe chālavā man. 57',
        'Em charitra kare bahunāmī, je koi sarve dhāmnā dhāmī; \n Jenī āganyāmā aj īsh, Vishņu Vibudh Shāradā Shesh. 58',
        'Jeni aganyāmā māyā kāļ, sarve lok vaļī lokapāļ; \n Jenī āganyāmā Vāyu Vyom, vaļī kahīe tej toy bhom. 59',
        'Eh sarvenā niyantā swāmī, kare em charitra bahunāmī; \n Nartan dharyu chhe te māt, em varate chhe Varnirāț. 60',
        "<b>Iti Shrīmadekāntik Dharma-Pravartak Shrī Sahajānand Swāmī shishya Nishkulānand Muni virachite  Bhaktachinitāmaņi madhye 'Hari-Tapashcharyā-Varanna' nāme oganatrismu prakaran. 29</b>",
      ],
    },
    {
      name: 'Bhaktachintāmaņi Prakaran 30',
      content: [
        '<b>Shrī Hari Gopāļ Yogīne malyā</b>',
        '<b>Pūrvachhāyo</b>',
        'Tyār pachhī Krushnadevnī, kahu kathā ati rasāļ; \n Charchai thayā chālavā, uttar dishāmā dayāļ. 1',
        'Nāmī mastak Muktanāthne, pachhī nāmyu muktane shīsh; \n Prabhāte āthī padhāriyā, eh ashramthī Jagadīsh. 2',
        'Nag nadiyo taļāv tyā, tarī utaryā te pār; \n Mahā aranya jyā manushya nahi, chope chālyā teh mozār. 3',
        'Himāchai bhanī chāliyā, joyā tenī talātīnā van; \n Zād pahad joi pruthvi, joyā vividhe vrūksha saghan. 4',
        '<b>Chopāi</b>',
        'Zād pahād ucha chhe apār re, jānyu adyā ākāsh mozār re; \n Sāmsāmī  shākhā sarkalānī re, ek bījāmā ghanī ghūchānī re. 5',
        'Vadī vidhye vidhye veli jeh re, ek bījāmā uzarī teh re; \n Van velī ghūchāni chhe ghātī re, jene joi chhāti jāy fātī re. 6',
        'Evu ghātu van chhe visham re, jemā na pade rātyadinī gam re; \n Na dise ūgī āthame dan re, evu zāde chhe van saghan re. 7',
        'Tyā fal fül fülyā kai re, kand muratano pār nai re; \n Vaļī sar saritā apār re, ati amaļ jaļ te mozār re. 8',
        'Vadļ geherī gufā tyā ghanī re, jānu bahu rahyā mandir banī re; \n Vaļī pashu ne pakshī tyā ghaņā re, fare toļā boļā tehī tanā re. 9',
        'Sīnha shārdül kāve kesarī re, kapi kurang ne kai karī re; \n Geridā roz ne mahishā ghanā re, vyāghra vārāh bahu biyāmanā re. 10',
        'Surāgāyo ne semar shyāl re, shashā noi bolā tyā vyāl re; \n Jyāre bole paraspar eh re, thāye shabda bhayankar teh re. 11',
        'Manushya jātie tyā na javāy re, jo jāy to pāchhu na avāy re; \n Evā vannā ekā-ek fare re, ati dhīr koīthī na dare re. 12',
        'Bhūt pret danuj ne daitya re, evā maļe van māhi nitya re; \n Yaksha rākshas rākshasī jeh re, bhairav bhairavī vaitālī teh re. 13',
        'Evā ahonish vanmā rame re, tyā Hari ekā-ek bhame re; \n Jātā jātā pade rātya jiyā re, suve nirbhay thaīne tyā re. 14',
        'Em jotā te van samagra re, ävyu ek tyā Butol nagra re; \n Teno rājā Mahādatt nām re, sarve parvatī-rājāno shyām re. 15',
        'Tene dīthā tyāgī Ghanashyām re, ati hete rākhyā nij dhām re; \n Kare ati prītye nitya sev re, jāne a chhe motā koi dev re. 16',
        'Nrup bhaginī nām mayājī re, dekhī Harī thayā bahu rājī re; \n Kahe ā to moţā koi ati re, noy manushyanī āvī gatī re. 17',
        'Joi Harinā motā ācharan re, seve kalyān sāru te charan re; \n Pachhī Nāthe dayā karī tene re, āpyu nij gnān eh bene re. 18',
        'Janma-maran tanujā: kāpyu re, sukh antare akhand āpyu re; \n Rahyā tyā thodā ghanā din re, pachhi tyāthī chālyā chhe jivan re. 19',
        'Tap karavā ishak chhe ati re, bijī vāt te nathī gamati re; \n Shaher pur nagra ghosh gām re, nathī gamatu rahevā e thām re, 20',
        'Medī mahol havelī āvās re, temā rahetā rahe chhe udās re; \n Māte vege chālyā tyāthī vane re, taiye rājī thayā bahu mane re. 21',
        'Muktanāththi āvyā e Aranya re, tene vītī gayā kāj tran re; \n Chalyā gahan vanne māí re, khāvā fai fül nitya tyāī re. 22',
        'Te pan maļe ke na maļe tāne re, toy man adhīr na āne re; \n Evā thakā vichare chhe vane re, ati tyāg vairāgya chhe tane re. 23',
        'Jātā uttar dishne māīre, avyo vad rūdo ek tyāi re; \n Tyāthī nadi talāv nikat re, ati ūcho vistāre chhe vatre. 24',
        'Tene ās-pāse gaj fare re, bījā shabda bhayankar kare re; \n Tyāthī ugamanu ek tāl re, vahe uttarmā jalamal re. 25',
        'Nad thumbade vītyo chhe vad re, bethā dīthā yogī tene thad re; \n Mrugājin par bethā āp re, māthe jatā modī chhe nishpāp re. 26',
        'Achhādane dhākel kaupīn re, nathī te pan vastra navīn re; \n Teh vină nathī bīju pās re, māyik sukhthi chhe udās re. 27',
        'Shālagrāmnī sevā kidhī chhe re, Gītā pāth karavā līdhī chhe re; \n Eva Gopā: Yogī udār re, tene Nāthe karyo namaskār re. 28',
        'Tyāre übhá thai Yogīrāy re, malyā het ānī ur māy re; \n Jem vahālā vegalethī āve re, tene malejem ati bhāve re. 29',
        'Em pāmyā chhe ati ānard re, pachhī maļī bethā muni-ind re; \n Kahyu ek-bījānu vruttāñt re, tyāre vādhiyu het atyart re. 30',
        'Kahe āpane raheshu be maļi re, bolyā Prabhujī evu sāmbhaļī re; \n Kahyu tame guru ne hu shishya re, apo rūdo mane upadesh re. 31',
        'Rahyā Gopāļ Yogīne pās re, karyo Yog-Shāstrano abhyās re; \n Kāve je koī aşhțāng yog re, shikhyā jethī mate bhavarog re. 32',
        'Motī buddhivālā Ghanashyām re, shikhyā Yog ang kahu nām re; \n Yam niyam āsan jeh re, prānāyām pratyāhār teh re. 33',
        'Dhāranā vaļī dhyān je kahīe re, ashtamu ang samādhi laie re; \n Temā jūjavā bhed chhe bahu re, shikhyā thode dine Hari sahu re. 34',
        'Ek vār sambhaļe chhe jeh re, shīkhī karī dekhāde chhe teh re; \n Vaļī shikhya chhe pratham pele re, te pan karī dekhāde chhe chhele re. 35',
        'Basti be prakārnī lahīe re, neti kunjar-kriya te kahī e re; \n Noļi Shankha-prakshālan nām re, morya shikhya chhe e Ghanashyām re. 36',
        'Te to sarve dekhāde chhe karī re, guru Gopāļ Yogīne Hari re; \n Joi guru kare chhe vichār re, noy manushya ā niradhār re. 37',
        'Āto Krushna chhe Golok-pati re, āvyā chhe pote dharī mūrati re; \n Avo huje ati nispruh re, tene na thāy bīje saneh re. 38',
        'Māte jāņu chhu jarur Krushna re, eve bhāve kare nitya drashna re; \n Em paraspar gurupaņu re, rākhe ek bījā māi ghanu re. 39',
        'Kaņd mūļ faļ fül vaļī re, jame ānaņde ekathā malīre; \n Em vityu e sthānake varsha re, karī ugra tap thayā krush re. 40',
        'Shīt ushna ne meghnī dhār re, sahyu sarve sharīr mozār re; \n Em tap kare van māyre, ati dhirajaya parvat prāy re. 41',
        'Evā yogī moțā je dayāļ re, joi pāse vasyā pashupāļ re; \n Rahyā gāyo taļā ghosh karī re, tyā to kedye padiyā kesarī re. 42',
        'Nitya kare te gāyonīghāt re, tenī kahī yogī āge vāt re; \n Kahe govāļ ame anāth re, amāre chhe e gāyo mīrāth re. 43',
        'Tene vāgh karashe jo nāsh re, tyāre amāre sai gujāsh re; \n Evī sārbhaļī dīnatā vāņi re, bolyā yogī tene dukhījānī re. 44',
        'Kahe ma biyo tame govāļ re, karashe Hari sahunī rakhavāļ re; \n Em kahī līdho Shankha hāthe re, vajādiyo chhe te Yogīnāthe re. 45',
        'Jetalāmā sambhaļāno shabda re, bhāgyā hisak mūki e had re; \n Vaļi tyā vasatātā je vāgh re, tene pan karyu van tyāg re. 46',
        'Rahyā sukhe gāyo ne govāļ re, Prabhu goubrāhman pratipāļ re; \n Rahyā kāīk pote pachhi tyāy re, vāche Gītāno bijo adhyāy re. 47',
        'Pachhī ātmānu shuddha swarūp re, nishchey karīyu jevu chhe rūp re; \n Pratyāhār karī mahāmati re, indri prān antahkaran vrutti re. 48',
        'Tene ātamāmā vāļī līdhī re, pachhī dhiraje dhāranā kīdhī re; \n Em ketalāk kāl gayā re, ātamāne vishe sthir rahyā re. 49',
        'Dhyān-yog tenī je samādhi re, tenī pakava dashā ati sādhi re; \n Kahyu em yogīne jīvane re, evī kriyāo kare chhe vane re. 50',
        "<b>Iti Shrīmadekāntik Dharma-Pravartak Shri Sahajānand Swāmi shishya Nishkuļānand Muni virachite Bhaktachintāmaņi madhye 'Shrī Hari Gopāl Yogine malyā e nāme trismu prakaran. 30</b>",
      ],
    },
    {
      name: 'Bhaktachintāmaņi Prakaran 31',
      content: [
        '<b>Shrī Haricharitra - Gopāļ Yogīne siddhagati</b>',
        '<b>Pūrvachhāyo</b>',
        'Vaļī kahu ek vāratā, suno sahu thai sävadhān; \n Krupānidhi Krushnadevnā, kahu charitra amrut samān. 1',
        'Nāth pratāpe nijātamā, dekhe akhand rūp anup; \n  Tenī brahma sāthe karī ekatā, pachhī thayā brahmaswarūp. 2',
        'Evā yogne shikhiya, Gopāļ Yogī jeh; \n Snehe karī Shri Harie, tene shikhaviyu chhe teh. 3',
        'Tene karī tarta thayā, yogī te brahmaswarūp; \n Ati prakāshak ātamā, teh jānyu potānu rūp. 4',
        '<b>Chopāi</b>',
        'Pachhī yogīne jaņāņu em, āne manushya kahevāy kem; \n Thayu gnān yathārath jyāre, bolya Gopāļ Yogī te vāre. 5',
        'Naranārāyan Rushirāy, thai brahmachārī āvyā āy; \n Bahu jīvnā karavā kāj, āpe pragat thayā Mahārāj. 6',
        'Em nishchay karyo niradhār, Nārāyan te ā brahmachār; \n Evā jānī pachhī dharyu dhyān, chotī vrutti mūrtimā ektān. 7',
        'Tene visariyo nij deh, thayo mūrtimā ati saneh; \n Jānyu āp malyu sukh motu, bīju sarave lāgyu chhe khotu. 8',
        'Evā motā yogī je Gopāļ, te to bhūlyā deh thode kaļ; \n Ati vismruti thai jyāre, mūkyo māyik dehne tyāre. 9',
        'Pachhī Shrī Krushnadev pratāpe, gayā Golokmā yogī ape; \n Pachhī brahmachārī Nīlakanthe, karī kriyā tenī rūdī pethye. 10',
        'Pachhī mukī pote eh sthān, chālyā pūravmā Bhagawān; \n Rahī akhand brahmaswarūp, chālyā āpe āp brahmarūp. 11',
        'Nāsāgra drashți karīne sthir, chālyā jem kamānno tīr; \n Krushna māhi chhe drashți akhand, nathī dekhatā pind brahmānd. 12',
        'Dish vina nathī bījo rāh, gayā tya thakī adi Vārāh; \n Tiyā tran divas pote rahyā, tyānā vāsīne darshan thayā. 13',
        'Nirkhi ānard pāmiyā ati, jānu āvyā shu à Bruhaspati; \n Em janne ānand alī, pachhi tyā thakī nisaryā chālī. 14',
        'Gayā banigadeshmā dayāļ, ävyu Sīrapur shaher vishāļ; \n Siddhavallabh teno chhe rāye, gayā pote tyā an-ichchhāye. 15',
        'Te nareshe prārthanā karī, rākhyā chomāsu tyā bhāv bharī; \n Dithā ati tyāgī ekā-ek, rākhyo pāsaļe ek sevak. 16',
        'Tenu nām chhe Gopāļadās, kare telya rahe nitya pās; \n Tiyā bijā bhekhadhārī bahu, rākhyā chomāsu karavā sahu. 17',
        'Te tamogunī mantra adhyāsī, sarve kshudradevnā upāsī; \n Tenā jūjavā jūjavā vesh, koi mūndel kenek kesh. 18',
        'Koi nāgā koine kaupīn, koi na rākhe vastra navīn; \n Temā so-ek tapasvī saī, te to bese tadakāmā jaī. 19',
        'Koi varnī ne koī sariyāsī, koi hansa ne koī udāsī; \n Koī kahe mukhe kāļī kāļī, koi kahetu becharāvāļī. 20',
        'Koi bhairav bhairav rahyā bhanī, koi bhaje bhavānī joganī; \n Koi muni ne koik bole, koi ahonish ānkhya na khole. 21',
        'Kaik khākhī kaik hudadhangā, em malyā bahu adabangā; \n Thayā siddha bhūsī bahu shelī, bhangī jangī bheļā thayā felī 22',
        'Tene rājā jānī motā siddha, āpe rasoiyo rūdī viddha; \n Asan sāru āpī gādalā, kare sanamān rājā bhalā. 23',
        'Tyāre yogī bole baļe bahu, kare vāt siddhāinī sahu; \n Em karatā āvyo varashāt, vāyo vāyu thayo utapāt. 24',
        'Khave vijaļī vāramavār, varashe megh te mushaļadhār; \n Garaje ghor ne kadākā kare, ūpare jaļ akhand zare. 25',
        'Evī akhand mandānī elī, chālyā pruthavīe pūr relī; \n Temā podhī rahyā pote Nath, tan par chadī retī hāth. 26',
        'Pachhī sevak āvī savāre, kādhyā kādav māhithī bāre; \n Varshe megh machī bahu zadī, ankhya ughade nahi ek ghadī. 27',
        'Em chāre mās vutho ghan, pahoche siddhāi ketalā dan; \n Ati varshāte asoyā thayā, pachhī rātye rātye bhāgī gayā. 28',
        'Dhire dhire siddha gayā nāsī, karavā lāgyā lok tenī hāsī; \n Kahe moțā siddha gayā chālī, ā jo padyā chhe āsan khālī. 29',
        'Ek bethā rahyā brahmachārī, tenejoi namyā nar-nārī; \n Kahe siddha to ā ek kharā, bijā dambhī bhāgi gayā parā. 30',
        'Rājā namyojānī Hari moțā, bījā sarvene jānya chhe khotā; \n Vādhyu Prabhujīnu bahu mān, bījānu na kare sanamān. 31',
        'Tene karī balyā bījā bahu, āvyā maļī māravāne sahu; \n Nākhī adad mantarī mūthu, padyuje je karyu te te jūthu. 32',
        'Pachhī sevak hato je pās, karato sevā je Gopāļadās; \n Tene māthe nākhī ene mūthya, padyo bhūmie na fere puthya. 33',
        'Avyu modhe fīn tene joi, nahi jīve kahe sahu koī; \n Pachhī rājāe siddha bolāvī, kahyu āne levo ja uthāvī. 34',
        'Tyāre siddhane chhe marad bhārī, kahyu jīvādashe brahmachārī; \n Tyāre rāye jodyā āvī hāth, kahyu āne jīvādīye Nāth. 35',
        'Pachhī Hari tene pāse jai, uthädyo Shrī Krushna mantra kai; \n Uthyo tarta lāgī nahi vār, pāmyā vismaya sahu nar-nār. 36',
        'Kahe ā to chhe pote Shrī Kruşhņa, motā bhāgya thayā enā draşhņa; \n Vaļī em kahe nar-nārī, Krushna nahi to Krushna bhakta bhārī. 37',
        'Pachhī mūthya nākhīti je siddhe, padī tene māthe bhalī vidhye; \n Padyo padāk pruthavī māī, mukhmā gai dhüdya bharãi. 38',
        'Avyu modhe fīn fatyu dachu, thayu jīvyānī kornu kāchu; \n Pachhī tene tenā siddha lāvī, karyā upāy bahu bolāvī. 39',
        'Tene fer padyo nahi rati, tyāre karī Harine vinati; \n Pachhī Nāth tеne pās āvī, kahī mantrane lidho jīvāvi. 40',
        'Tyāre siddha rājā sahu maļī, karī stuti Harijīnī vaļī; \n Jānyā rājāe motā chhe sare, thayo parivār sahit ashare. 41',
        'Jeje hato e siddhano vartā, mūthya nākhī bāļī pet bhartā; \n Te to Nilakanthe bhārgyo bhay, thayā manushya sahu nirabhay. 42',
        'Lāvī vastra dhan āge dhare, Hari tyāgī chhe tene shu kare; \n Etalākmā vipra tailang, ävyo nārī sut lai sang. 43',
        'Bhanyo Ved Shāstra ne Purān, prasiddha vipra prūthvī pramān; \n Avyo Siddhavallabh rāy pās, mane dan levānī chhe āsh. 44',
        'Pachhī te rājā chhe dharmavān, āpyu bhanyo jānī bhāre dān; \n Apyo hasti ne kāla purush, letā vipra thayo kāļo mash. 45',
        'Gaur matīne thayo chhe shyām, tyāre nindā karavā lāgyu gām; \n Pachhī Hari pāse vipra āvī, ati dīnatāe vānī kāvī. 46',
        'He Mahārāj ! Hu to hato dukhī, dan lai thavā gayo sukhī; \n Tyā to sāmu dukh thayu ghanu, have shu māhātmya jīvyā tanu. 47',
        'Māte tyāgīsh hu have tan, thāy to tame karo jatan; \n Pachhī dukhī vipra Harijānī, dayāle dayā e par ānī. 48',
        'Kahyo Shrī Krushnano mantra kāne, matī shyām thayo gaur vāne; \n Pachhī brāhman lāgyo pāy, kahyu rahyo hu mankhyā māy. 49',
        'Pachhī gāto Prabhujīnā gun, gayo desh potāne brāhman; \n Evā Prabhujī bahu pratāpī, karyā sukhī bahu dukh kāpī. 50',
        'Tyāg vairāgya urmā ati, sahu par varte mahāmati; \n Em karatā gayu chomāsu, āvyo Kārtik ūtaryo āsu. 51',
        'Pachhī Harine bījā je siddha, tene pūjyā nrupe bahuvidh; \n Pachhī chalyā chhe tya thakī sahu, Hari sange bījā siddha bahu. 52',
        'Avyā Kāmākshi devīnī zādīe, ūtaryā siddha sahu vādīe; \n Pachhī rasoi karavā kāj, karyo bhelo sarave samāj. 53',
        'Tene samīpe chhe ek gām, vase dvij tyā Pibek nām; \n Siddha mandaļ āvyu sāmbhalī, uthyo tarta tiyā thakī balī. 54',
        'Kahe siddhāi enī chūthī nākhu, karī gulām ne gher rākhu; \n Jojyo Mātājīe karī mer, āvyā van gotyā pashu gher. 55',
        "<b>Iti Shrimadekāntik Dharma-Pravartak Shri Sahajānard Swāmi shishya Nishkulānand Muni virachite Bhaktachintāmani madhye 'Shrī Haricharitra' nāme ekatrīsmu prakaran. 31</b>",
      ],
    },
    {
      name: 'Bhaktachintāmaņi Prakaran 32',
      content: [
        '<b>Shrī Haricharitra - Pībaik Akhyān</b>',
        '<b>Pūrvachhāyo</b>',
        'Shubhamati sahu sāmbhaļo, Hari-kathā kahu anup; \n Duşhtane dukhadāyī chhe, chhe santane sukharūp . 1',
        'Asur je avani rahyā, badalāvīne bijo vesh; \n Tene te arthe Shrī Hari, fare chhe desh pradesh. 2',
        'Je kāran avatar chhe, te karavā thayā chhe taiyār; \n Hari ichchhāe āviyā, Prabhu eh vādī mozār. 3',
        'Pibaik tyā pariyāniyo, karavā te siddhonī ghāt; \n Nidān temāthī je nipajyu, tenī sāmbhalajyo sahu vāt. 4',
        '<b>Chopāi</b>',
        'Hato brāhman morya e shuddha , maļī vāmie karyo ashuddha ; \n Kaulāvarnave bhanele bhūdeve, maļī bhrashta karyo tatakheve. 5',
        'Thayo Kālī upāsak bhārī, nitya pratye pive fulvāri; \n Vaļī siddhane jītavā kāj, tedi sajyo sarave samāj. 6',
        'Madya mās khāi thayo mast, tikhu trishūl līdhu chhe hast; \n Karyo siridūr lep lalāte, chālyo siddhanejītavā māte. 7',
        'Raje bhinjel kumkum lai, chālyo kapāļe chāndalo dai; \n Vaļī fuļvārī khub pīdhu, vadhyu te sharīre chhāntī dīdhu. 8',
        'Chālyo matsya chāvī madamāto, shishe bāndhyo chhe patako rāto; \n Māthe ghanā ghūchāļā muvāļā, te dise chhe Bhūndā bhamarāļā. 9',
        'Siddha prasiddha pote kahevāy, tene jāne sahu desh māy; \n Koi sāmu āvīne na bhākhe, bhākhe jo koi to mārī nākhe. 10',
        'Evo Bhūndāye bharyo apār, madya chār vārno pinār; \n Kanthe bāndhyā chhe asthi mānjār, thayo siddha jītavā taiyār. 11',
        'Nar-nārī je rahe gām vāse, sange lai āvyo siddhane pase; \n Vaļato āvīne boliyo em, pākhandiyo siddha kāvo kem. 12',
        'Siddha to ek hu chhau āj, tam jevā to chhe māru khāj; \n Motā motāne me jīti līdhā, tam jevā shishya kai kīdhā. 13',
        'Jeje āvīne mane namiyā, te te sarave jīvatā riya; \n Jene jene bāndhī muj sāthe, tene māryā mūkī vīr māthe. 14',
        'Māte tame manmā vichārī, thāo shishya māļāo utārī; \n Utāro upavīt achir, nahi to hamaņā hokāru chhu vīr. 15',
        'Bhūt pret lāvī sange ghanā, khāī jāshe mās tam tanā; \n Em bolyo e baļmā bahu, binā Hari vinā siddha sahu. 16',
        'Kahe jem e kahe tem karīe, to āvyā mot māthī ugarīe; \n Nahi to mārashe vīrne melī, māte melīe māļā sankelī. 17',
        'Melo janoi par utārī, em sahu siddhe vāt vichārī; \n Tyāre Harie siddha pratye kahyu, rākho dhīraje hākalī haiyu. 18',
        'Kare shishya more mar mane, pachhī thāvu tamāre sahune; \n Toy sīddhe dhīraj na dhārī, kahyu nākhashe tamane mārī. 19',
        'Mahā dushta e papī chhe bahu, ene ame jānu chhau sahu; \n Evī siddha Harinī je vānī, suņī bolyo ati krodh ānī. 20',
        'Tyāre Pibaik kahe brahmachārī, tane dekhādu sāmarthī mārī; \n Jo tu ā nīlā vadnā hāl, hamanā karu chhu sūkavī sāl. 21',
        'Em kahī nākhī mūth jyāre, vad sūkī gayo teh vāre; \n Kahe mānya Varnī vāt mārī, nahī to ā gati jānaje tārī. 22',
        'Tyāre Hari kahe na biyu ame, karavu hoy te karo sukhe tame; \n Em kahīne Vīrāsane, bethā Hari te achaļ mane. 23',
        'Bijānī to dhīraja na rahi, bethā Krushna kede kampe jai; \n Pachhī Pibaike adad mantarī, nākhyā Hari upar rīs karī. 24',
        'Tene Harine na thayu kāi, tyāre dvij kopyo man māī; \n Kahe raheje khabaradār thai, āj māryā vinā mūku naī. 25',
        'Em kahī nākhī mūth ene, fer padyo nahi kāī tene; \n Tyāre Pibaike pan e lidhu, tene māryānu nishchay me kīdhu. 26',
        'Karavu hoy te karya smaran, āj āvyu tāru chālī maran; \n Nākhu chhu kāļbhairavnī mūthu, tārā jīvavānu jāne jūthu. 27',
        'Tyāre Hari kahe betho chhu hu ja, karavu hoy te karne tu ja; \n Tyāre mūkyā chhe Bhairav vīr, toy Hari bethā rahyā sthir. 28',
        'Avyā Bhairav ne vīr doī, teto sāmu shakyā nahi joī; \n Pachhā Pibaik upar padyā, ulaţā nākhatalne nadyā. 29',
        'Padyo kāļī upāsak dhaļī, chālyu mukhethì lohī nikaļī; \n Avī mūrachhā na rahi shūdh, padyo avanīe ūdha-mūñdha. 30',
        'Pachhī modethī mūrachhā vaļī, ūthī bolyo chhe baļmā vaļī; \n Kahyu übho rahejve brahmachārī, melu Batubhairav nākhe mārī. 31',
        'Tyāre Hari kahe mokalo sukhe, mūkyā Batuvīrne vimukhe; \n Te to bine pāchhā vaļī gayā, pāchhā Pibaikne vaļagiyā. 32',
        'Nākhyo bhūmie pādī padāk, dhrūjī dharanīe padyo dhadāk; \n Vaļī tadi tad-fadi uthyo, bolyo Prabhujī upara rūthyo. 33',
        'Kahe mūku chhu vīr Mahākāļī, tene nahi māre bījā etāļī; \n Em kahīne tene mūkīyā, te to Hari pāse na āviyā. 34',
        'Pāchhā farīne lagiyā ene, dhaļī pādiyo bhūmie tene; \n Thayo asoyo na rahī shūdh, toy vāmī na mūke viruddha. 35',
        'Padī pahor üthi ūbho thayo, vaļī Prabhujīne kahevā rahyo; \n Kahe chhe übho rahe je brahmachārī, have karu chhu vale hu tārī. 36',
        'Bahu vīr sahit Hanumant, mūku tene kare tāro art; \n Em kahīne mūkyā te vār, āvī tene karyo namaskār. 37',
        'Karī pranipat pāchhā gīyā, bahu Pibaik par kopiyā; \n Avī vaļagyā te viprane sahu, padyo vipra bhūnde hāla bahu. 38',
        'Fātyu modhu ne āviyu fīn, padi anganī nādiyu kşhīn; \n Māthu garī gayu mahī māī, mukhmā gai dhūlya bharai. 39',
        'Padyo bhechak thaī rītya bhūndī, ākhyo ūtarī gai chhe ündī; \n Nāk mukh māthī lohī vahyu, pachhī ūthavā jevu na rahyu. 40',
        'Tyāre tenā sambandhī sahu maļī, lāgyā pāy Prabhujīne laļī; \n Kahe dayā karo ene Hari, have nahi kare e āvu farī. 41',
        'Māgato hato te fal maļyu, mahā Ahankārīnu mān gaļyu; \n Pachhī Prabhue tene uthadyo, uthī vipra Prabhu page padyo. 42',
        'Karyā dandavat bahu vār, kahe āvyo nave avatār; \n Pachhī siddha hato tene joī, āpī brāhmane tene rasoi. 43',
        'Em karī gayo gher jyāre, man rahyu nahi evu tyāre; \n Pūjyo Kāļbhairavne jai, madhya mās baļidān dai. 44',
        'Mūkyo Hari māthe tatakāļ, āvyo bhayankar vikarāļ; \n Bhūndu mukh te bharyu rūdhire, lāmbo ne nathī vastra sharīre. 45',
        'Akhyu rātī ati kāļo shāhī, līdhu trishūļ te kar māhi; \n Eve rūpe Prabhu pāse avyo, pan āvīne kāi na fávyo. 46',
        'Chhete besī rahyo ākhī rātya, Hari hasyā joi parabhātya; \n Pachhī nāvā chālyā jyāre Hari, tyāre enā sāmī drashți karī. 4',
        'Tyāre thar thar dhrūjīne bhāgyo, jai Pibaikane kede lāgyo; \n Kahe aj nishchey ene māru, tyāre Prabhujīe karyu vāru. 48',
        'Kahe enu khādhu siddhe anna, tenī tāre karavī jatan; \n Pachhī brāhman pāse bhairav, jai kahī chhe vāta sarava. 49',
        'Āj mrutyu karavu tu tāru, pan Varnie karyu chhe vāru; \n Em kahīne bhairav gīyo, dvij Prabhune pāse āviyo. 50',
        'Jānī īshvar nāmiyu shish, kahe karajyo gunā bakashish; \n Em kahīne vāramavār, kare bahu potāne dhikkār. 51',
        'Kahe bhanī ganī bhajyā bhūt, karyā bahu Bhūndā karatūt; \n Karyā kukarma tajī āchār, evo pāpī hu tene dhikkār. 52',
        'Em kahī grahyu Haricharan, Prabhu avyo hu tamāre sharan; \n Em kahīne thayo chhe shishya, jānī Mahārājne Jagadīsh. 53',
        'Dhāryo prathamnā jevo dharma, mūkyā bījā je karavā kukarma; \n Kaulārnavādi grantha sambhāļī, shāstra bāhya jāņī didhā bāļī. 54',
        'Bhāgawat Gītā pachhī bhanyo, sācho bhakta Shrī Krushnano banyo; \n Evu charitra karī dayāl, pachhī tyāthī chālyā tatakāị, 55',
        'Hatā siddha tene shīkh dīdhī, pote vāt navalakhānī līdhī; \n Manushyākruti sāmarthi apār, dhanya janamodan bhandār. 56',
        "<b>Iti Shrimadekārtik Dharma-Pravartak Shrī Sahajānarid Swāmī shishya Nishkulānand Muni virachite Bhaktachintāmani madhye 'Shrī Haricharitra' name batrismu prakaran. 32</b>",
      ],
    },
    {
      name: 'Bhaktachintāmaņi Prakaran 33',
      content: [
        '<b>Shri Haricharitra – Nav lakh Yoginu Kalyan</b>',
        '<b>Pūrvachhāyo</b>',
        'Bahu nāmī Krushnadevnā, suno charitra sarve rasāl; \n Bhakta abhakta kārane, fare hadhya behadhye dayaļ, 1',
        'Santne sukh āpavā, devā dushta janne dand; \n Tej kāran Prabhue, pruthavīe dharyu chhe pand. 2',
        'Jīyā jīyā jāvu ghate, tiyā tiyā jay jarūr; \n Sukh dukh bhūkh pyāsnu, nathī ganatā nikat dūr. 3',
        'Pachhī ekā-ek chaliyā, mahā vikat adri māy; \n Navlakh yogīne nirakhavā, chālyā Prabhujī bahu uchhay. 4',
        '<b>Chopāi</b>',
        'Chālyā parvat pekhavā Hari, āvyo ati vasamo adari; \n Jāvā vāt jade nahi jīyā, jāvu jarūr potāne tiyā.5',
        'Jātā e dishe manushye vāryu, pan karyu chhe potānu dhāryu; \n Chadyā parvat upar pote, chālyā shail tanī shobhā jote. 6',
        'Avī nav lakh dhūnī rūpāļī, te to saheje baļe vanabāļī; \n Tyā kund dīthā chhe apār, bharyājaļ amaļ te mozār. 7',
        'Kyāk tādhājaļ kyāk ūnā, em bahu kund dīthā nīrunā; \n Pachhī tyānā rahenārā je siddha, maļyā te pan āvī prasiddha. 8',
        'Jenī koine bheta na hoy, mar motā mukta yogī hoy; \n Tene sahune malyā ek vār, karyā paraspar namaskār. 9',
        'Pachhī preme bethā sahu pās, pūchhyo yogīne yog abhyās; \n Kahyu teñe tenu varatant, sunī Hari harakhyā atyant. 10',
        'Kahyu siddhane chho dhanya dhanya, em kahī rahyā tranya dan; \n Api ānand tyāthī ūtaryā, chālyā Hari bahu mod bharyā. 11',
        'Nav lākh yogīne nirakhī, chālyā hasti mārage harakhī; \n Tyāthī shūdh uttarmā vaļī, rahyo Rāmakot jaļamaļi. 12',
        'Tiyā jāvānu kļdhu chhe man, pan tyā to na gayā jīvan; \n Jotā van parvat vishāl, gayā bālavā Kunde dayāl. 13',
        'Joyo Kund e jugate karī, tranya rītyane rahyo te dharī; \n Vāyu agnijal tranya maļī, tenā samoh rahyā nikaļī. 14',
        'Tiyā rahyā pote tranya dan, pachhī tyāthī chālyā Bhagwan; \n Karyo agni khūne paravesh, chālyā jotā jotā sarve desh. 15',
        'Gayā Gangā Sindhune sangame, nāyā temā pote jai same; \n Rahyā tranya divas tiyā Hari, chālyā samudra khādī ūtarī. 16',
        'Avyā Kapilajīne ashrame,jeh jāyagā sahune game; \n Chyāre kore shobhe samudar, madhye ashram ati sundar. 17',
        'Sānkhya-Shāstranā achārya jeh, tenā guru Kapilajī teh; \n Kare chhe tap pote samarth, sarve jīvnā kalyān arth. 18',
        'Gnān vairāgya bhakti ne dharma, yog sahit pānch je parma; \n Tenā sthāpannā karanār, evā Kapilanā karyā dedār. 19',
        'Rahyā Bhakti sut tiyā mās, pachhī tyāthī chālyā avināsh; \n Avyā Purushottam purī māī, nirkhyā Jagannāthajīne tyāi. 20',
        'Pachhī rahyā pote eh thām, kāyek dhāryu chhe karavu kām; \n Nāi samudramā jai nitye, nirkhī Jagannāthajīne prītye. 21',
        'Karyu āsan shaherthī bāru, Indradyumna sar joī sāru; \n Tiyā dīthā chhe asur ghanā, vesh laī siddha sādhu tanā. 22',
        'Kām krodh ne matsar ati, māho māhi chhe vairnī mati; \n Dharma dveshī kapatī ne kāmī, vesh Shaivā  Vaishnavī ne Vāmī. 23',
        'Mantra jantra jāņe chhe apār, tene vash karyā nar-nār; \n Mukāvī varnāshramno dharma, karya bhrashta bagādī besharma. 24',
        'Motā motā sādhu sevā faļ, kahī dekhāde nārī āgaļa; \n Em kahīne dharmathī pāde, sādhu-nidānu pāp dekhāde. 25',
        'Evā adharme bharyā apār, dīthā Harie hajāro-hajār; \n Dharyā hāthe tīkhā hathiyār, līdhā dhokā chharā ne katār. 26',
        'Khadag khāndā lākadī luvagyu, kar kamānyu salake sāngyu; \n Bahu bandhūku ne kok bān, chakra chīpiyā līdhā chhe pān. 27',
        'Parshu trishūļ barachhiyo lai, janjāļyo ādhye shastra chhe kai; \n Ati shūrā ichchhe nitya yuddha, ketalāk na rākhe āyudh. 28',
        'Ketalāk tyāgī tap karata, ketalāk saumya vesh dharatā; \n Kaulārnva granth vānche nitye, pūje Shakti Bhairavne prītye, 29',
        'Evā dīthā chhe Nāthe apār, mahā pāparūp bhūmibhār; \n Tiyā rahyā pote avināsh, karavā evā asurno nāsh. 30',
        'Pan potāthī na thāye rati, kā je rākhe chhe ahisā vrutti; \n Besī rahyā vichārī e vidh, tyāre loke jānyā chhe ā siddha. 31',
        'Em jāņīne puchhe chhe jeh, kahe Hari thāy tem teh; \n Tyāre lokne āvī pratītya, lāve anna vastra dravya nitya. 32',
        'Teh māyalu kāi na līye, bhūt bhavishyanu kahī diye; \n Em karatā te ek dan, ävyā pāsaļe asur jan. 33',
        'Kahe karya amāru tu kāj, lāvya jaļ indhanā samāj; \n Daī dāro ne bahu darāvyā, na karavānā kām karāvyā. 34',
        'Kare kām Jadabharat jem, tyāre bijā ke ma karo em; \n Taiye te shu bolyā krodh karī, eno paksha melo paraharī. 35',
        'Em vadatā padyo virodh, māho māhi upanyo karodh; \n Pachhī temā padyā tad behu, nirdā paraspar kare tehu. 36',
        'Em karatā bandhānu ver, ichhyā māravā nahi jene mer; \n Pratham to bolī tholī thai, pachhī uthyā lathā motā laī. 37',
        'Līdhī ladavā pahelī lākadī, te to paraspar bahu padī; \n Pachhī līdhā khādā kar māy, avyā sām-sāmā maļī dhāy. 38',
        'Nākhe barchhiyo bahu sām-sāmī, chāle luvagyu na rahe khāmī; \n Salake sāngya ne sanene tīr, lade māho māhe shūravīr. 39',
        'Kare parshunā bahu prahār, nākhe dhokā chharā ne kațār; \n Tīkhā trishūļ chāle tyā ghanā, kare ghā chakra chīpiyā tanā. 40',
        'Bandhko  janjaļyo kokbāņ, nakhe māho māhe assurān; \n Em jore mandānu chhe yuddha, pāmyā trās ākāshe vibudh. 41',
        'Vāje dhol nagārā jujāryo, chāle sām-sāmī taravāryo; \n Turī raņshingā bole Shankh, pade pāpīnā māthā Asankhya. 42',
        'Machyu yuddha rahī nahi manā, padyā pruthvīe dhad ghaņā; \n Tene dekhī harakhyā māsārī, kahe khāshu āj khūb karī. 43',
        'Bhūt pret ne avyā bhairav, pishāch yaksha rākshas sarva; \n Dākanī shākanī ne joganī, āvī bhukhī bhairavīyo ghanī. 44',
        'Kank kāg ne vaļī kūtarā, thaī grudha shiyāļ ne sarā; \n Padyā māthā prāthvī par rade, jānyu daitya ramī gayā dade. 45',
        'Kaik nā hāth pag kapānā, kaik nāsī bhāgīne chhapānā; \n Em yuddha thayu bahu per, dash-sahastra gayā Yam gher. 46',
        'Etalā to iyā taļ rahyā, bījā nāsī bhāgī pan gayā; \n Te to desh-pradeshe pravarī, bījā daityo āge vāt karī. 47',
        'Kahyu ek hato nāno bāl, tene jānatā ame dayāļ; \n Tene arthe vadhiyo virodh, muvā māhomāhi karī krodh. 48',
        'Tyāre asur boliyā em, ene olakhīye ame kem; \n Tyāre kahyu āpīe endhān, tene pade tamane pechhān. 49',
        'Ati tyāgī tapasvī chhe tane, nathī lobhāto nārī ne dhane; \n Rahe chhe samādhimā din rāt, vaļī jāne chhe mannī vāt. 50',
        'Ethī padyo paraspar bhed, thayo tene āpano uchchhed; \n Māte maļe to melavo nahi, evī vāt asurne kahī. 51',
        'Evu sunī ne bolyā asur, have got shu ene jarūr; \n Jo maļashe to mārashu chhaļe, em baridhānu ver saghaļe. 52',
        'Em daitye karyu pariyān, tene jāne chhe Hari sujān; \n Kahe chhe jem thashe tem thīk, nathī ātamāne kenī bīk. 53',
        'Em kahi joyu tatakhev, dīthā daivī sampattinā jīv; \n Tene pote upadesh āpī, karya sukhī bhavadukh kāpī. 54',
        'Āje Hari-charitra anūp, Krushna-bhaktane chhe sukharūp ; \n Tene kaheshe sambhaļashe jeh, mahā kaşhtathī mukāshe teh. 55',
        'Ā lokmā pan sukhī raheshe, paraloke param sukh leshe; \n Pāp haranī katha chhe pavitra, jemā pragat Prabhunā charitra. 56',
        'Bhakta hashe te sunashe bhāve, abhaktane te arth nahī āve; \n Kaheshe hete sārbhaļashe kān, te par rājī thāshe Bhagawān. 57',
        "<b>Iti Shrīmadekāntik Dharma-Pravartak Shrī Sahajānand Swāmi shishya Nishkuļānand Muni virachite Bhaktachintāmani madhye 'Shrī Haricharitra' nāme tetrismu prakaran. 33</b>",
      ],
    },
  ],
};

export default niyam;
