import { NiyamConfig } from '../react-i18next';

const niyam: NiyamConfig = {
  name: 'Janmangal Namavali/Stotram',
  tabs: [
    {
      name: 'Janmangal Namavali',
      content: [
        '<b>śrījanamaṃgala nāmāvali</b>',
        '01. oṃ śrī śrīkṛṣṇāya namaḥ',
        '02. oṃ śrī śrīvāsudevāya namaḥ',
        '03. oṃ śrī naranārāyaṇāya namaḥ',
        '04. oṃ śrī prabhave namaḥ',
        '05. oṃ śrī bhakitadharmātmajāya namaḥ',
        '06. oṃ śrī ajanmane namaḥ',
        '07. oṃ śrī kṛṣṇāya namaḥ',
        '08. oṃ śrī nārāyaṇāya namaḥ',
        '09. oṃ śrī haraye namaḥ',
        '10. oṃ śrī harikṛṣṇāya namaḥ',
        '11. oṃ śrī ghanaśyāmāya namaḥ',
        '12. oṃ śrī dhārmikāya namaḥ',
        '13. oṃ śrī bhakitanandanāya namaḥ',
        '14. oṃ śrī bṛhadvratadharāya namaḥ',
        '15. oṃ śrī śuddhāya namaḥ',
        '16. oṃ śrī rādhākṛṣṇeṣṭadavai tāya namaḥ',
        '17. oṃ śrī marutsutapriyāya namaḥ',
        '18. oṃ śrī kālībharaivādyatibhīṣaṇāya namaḥ',
        '19. oṃ śrī jītendriyāya namaḥ',
        '20. oṃ śrī jītāhārāya namaḥ',
        '21. oṃ śrī tīvravairāgyāya namaḥ',
        '22. oṃ śrī āstikāya namaḥ',
        '23. oṃ śrī yogeśvarāya namaḥ',
        '24. oṃ śrī yogakalāpravṛttaye namaḥ',
        '25. oṃ śrī atidhairyavate namaḥ',
        '26. oṃ śrī jñānine namaḥ',
        '27. oṃ śrī paramahaṃsāya namaḥ',
        '28. oṃ śrī tīrthakṛte namaḥ',
        '29. oṃ śrī tairthikārcitāya namaḥ',
        '30. oṃ śrī kṣamānidhaye nam',
        '31. oṃ śrī sadonnidrāya namaḥ',
        '32. oṃ śrī dhyānaniṣṭhāya namaḥ',
        '33. oṃ śrī tapaḥpriyāya namaḥ',
        '34. oṃ śrī siddheśvarāya namaḥ',
        '35. oṃ śrī svatantrāya namaḥ',
        '36. oṃ śrī brahmavidyāpravartakāya namaḥ',
        '37. oṃ śrī pāṣaṃḍocchedanapaṭave namaḥ',
        '38. oṃ śrī svasvarupācalasthitaye namaḥ',
        '39. oṃ śrī praśāntamūrtaye namaḥ',
        '40. oṃ śrī nirdoṣāya namaḥ',
        '41. oṃ śrī asuragurvādimohanāya namaḥ',
        '42. oṃ śrī atikāruṇyanayanāya namaḥ',
        '43. oṃ śrī uddhavādhvapravartakāya namaḥ',
        '44. oṃ śrī mahāvratāya namaḥ',
        '45. oṃ śrī sādhuśīlāya namaḥ',
        '46. oṃ śrī sādhuvipraprapūjakāya namaḥ',
        '47. oṃ śrī ahiṃsayajñaprastotre namaḥ',
        '48. oṃ śrī sākārabrahmavarṇanāya namaḥ',
        '49. oṃ śrī svāminārāyaṇāya namaḥ',
        '50. oṃ śrī svāmine namaḥ',
        '51. oṃ śrī kāladoṣanivārakāya namaḥ',
        '52. oṃ śrī sacchāstravyasanāya namaḥ',
        '53. oṃ śrī sadyaḥsamādhisthitikārakāya namaḥ',
        '54. oṃ śrī kṛṣṇārcāsthāpanakarāya namaḥ',
        '55. oṃ śrī kauladviṣe namaḥ',
        '56. oṃ śrī kalitārakāya namaḥ',
        '57. oṃ śrī prakāśarupāya namaḥ',
        '58. oṃ śrī nirdambhāya namaḥ',
        '59. oṃ śrī sarvajīvahitāvahāya namaḥ',
        '60. oṃ śrī bhakitasampoṣakāya namaḥ',
        '61. oṃ śrī vāgmine namaḥ',
        '62. oṃ śrī caturvargaphalapradāya namaḥ',
        '63. oṃ śrī nirmatsarāya namaḥ',
        '64. oṃ śrī bhakatavarmaṇe nama',
        '65. oṃ śrī buddhidātre namaḥ',
        '66. oṃ śrī atipāvanāya namaḥ',
        '67. oṃ śrī abuddhihṛte namaḥ',
        '68. oṃ śrī brahmadhāmadarśakāya namaḥ',
        '69. oṃ śrī aparājītāya namaḥ',
        '70. oṃ śrī āsamudrāntasatkīrtaye namaḥ',
        '71. oṃ śrī śritasaṃsṛtimocanāya namaḥ',
        '72. oṃ śrī udārāya namaḥ',
        '73. oṃ śrī sahajānandāya namaḥ',
        '74. oṃ śrī sādhvīdharmapravartakāya namaḥ',
        '75. oṃ śrī kandarpadarpadalanāya namaḥ',
        '76. oṃ śrī vaiṣṇavakratukārakāya namaḥ',
        '77. oṃ śrī paṃcāyatanasanmānāya namaḥ',
        '78. oṃ śrī naiṣṭhikavratapoṣakāya namaḥ',
        '79. oṃ śrī pragalbhāya namaḥ',
        '80. oṃ śrī niḥspṛhāya namaḥ',
        '81. oṃ śrī satyapratijñāya namaḥ',
        '82. oṃ śrī bhakatavatsalāya namaḥ',
        '83. oṃ śrī aroṣaṇāya namaḥ',
        '84. oṃ śrī dīrghadarśine namaḥ',
        '85. oṃ śrī ṣaḍūrmivijayakṣamāya namaḥ',
        '86. oṃ śrī nirahaṃkṛtaye namaḥ',
        '87. oṃ śrī adrohāya namaḥ',
        '88. oṃ śrī ṛjave namaḥ',
        '89. oṃ śrī sarvopakārakāya namaḥ',
        '90. oṃ śrī niyāmakāya namaḥ',
        '91. oṃ śrī upaśamasthitaye namaḥ',
        '92. oṃ śrī vinayavate namaḥ',
        '93. oṃ śrī gurave namaḥ',
        '94. oṃ śrī ajātavairiṇe namaḥ',
        '95. oṃ śrī nirlobhāya namaḥ',
        '96. oṃ śrī mahāpurūṣāya namaḥ',
        '97. oṃ śrī ātmadāya namaḥ',
        '98. oṃ śrī akhaṃḍitārṣamaryādāya namaḥ',
        '99. oṃ śrī vyāsasiddhāntabodhakāya namaḥ',
        '100. oṃ śrī manonigahrayuktijñāya namaḥ',
        '101. oṃ śrī yamadūtavimocakāya namaḥ',
        '102. oṃ śrī pūrṇakāmāya namaḥ',
        '103. oṃ śrī satyavādine namaḥ',
        '104. oṃ śrī guṇagrāhiṇe namaḥ',
        '105. oṃ śrī gatasmayāya namaḥ',
        '106. oṃ śrī sadācārapriyatarāya namaḥ',
        '107. oṃ śrī puṇyaśravaṇakīrtanāya namaḥ',
        '108. oṃ śrī sarvamaṃgalasadrupa nānāguṇaviceṣṭitāya namaḥ',
        '\n<b>।। īti śrī janamaṃgala nāmāvali samāpta ।।</b>',
      ],
    },
    {
      name: 'Janmangal Stotram',
      content: [
        '<b>śrījanamaṃgala stotram</b>',
        'varṇiveṣaramaṇīyadarśanaṃ mandahāsarucirānanāmbujam ।\npūjitaṃ suranarottamairmudā dharmanandanamahaṃ vicintaye ॥1॥',
        'śrīkṛṣṇaḥ śrīvāsudevo naranārāyaṇaḥ prabhuḥ ।\nbhaktidharmātmajojanmā kṛṣṇo nārāyaṇo hariḥ ॥2॥',
        'harikṛṣṇo ghanaśyāmo dhārmiko bhaktinandanaḥ ।\nbṛhadvratadharaḥ śuddho rādhākṛṣṇeṣṭadaivataḥ ॥3॥',
        'marutsutapriyaḥ kālībhairavādyatibhīṣaṇaḥ ।\njitendriyo jitāhārastīvravairāgya āstikaḥ ॥4॥',
        'yogeśvaro yogakalāpravṛttiratidhairyavān ।\njñānī paramahaṃsaśca tīrthakṛttairthikārcitaḥ ॥5॥',
        'kṣamānidhiḥ sadonnidro dhyānaniṣṭhastapaḥ priyaḥ ।\nsiddheśvaraḥ svatantraśca brahmavidyāpravartakaḥ ॥6॥',
        "pāṣaṇḍochedanapaṭuḥ svasvarūpācalasthitiḥ ।\npraśāntamūrtirnirdoṣo'suragurvādimohanaḥ ॥7॥",
        'atikāruṇyanayana uddhavādhvapravartakaḥ ।\nmahāvrataḥ sādhuśīlaḥ sādhuvipraprapūjakaḥ ॥8॥',
        'ahiṃsayajñaprastotā sākārabrahmavarṇanaḥ ।\nsvāminārāyaṇaḥ svāmī kāladoṣanivārakaḥ ॥9॥',
        'sacchāstravyasanaḥ sadyaḥsamādhisthitikārakaḥ ।\nkṛṣṇārcāsthāpanakaraḥ kauladviṭ kalitārakaḥ ॥10॥',
        'prakāśarūpo nirdambhaḥ sarvajīvahitāvahaḥ ।\nbhaktisampoṣako vāgmī caturvargaphalapradaḥ ॥11॥',
        "nirmatsaro bhaktavarmā buddhidātā'tipāvanaḥ ।\nabuddhihṛdbrahmadhāmadarśakaścāparājitaḥ ॥12॥",
        'āsamudrānta satkīrtiḥ śritasaṃsṛtimocanaḥ ।\nudāraḥ sahajānandaḥ sādhvīdharmapravartakaḥ ॥13॥',
        'kandarpadarpadalano vaiṣṇavakratukārakaḥ ।\npañcāyatanasammāno naiṣṭhikavratapoṣakaḥ ॥14॥',
        'pragalbho niḥspṛhaḥ satyapratijño bhaktavatsalaḥ ।\naroṣaṇo dīrghadarśī ṣaḍūrmivijayakṣamaḥ ॥15॥',
        'nirahaṃkṛtiradroha ṛjuḥ sarvopakārakaḥ ।\nniyāmakaścopaśamasthitirvinayavān guruḥ ॥16॥',
        'ajātavairī nirlobho mahāpuruṣa ātmadaḥ ।\nakhaṇḍitārṣamaryādo vyāsasiddhāntabodhakaḥ ॥17॥',
        'manonigrahayuktijño yamadūtavimocakaḥ ।\npūrṇakāmaḥ satyavādī guṇagrāhī gatasmayaḥ ॥18॥',
        'sadācārapriyataraḥ puṇyaśravaṇakīrtanaḥ ।\nsarvamaṃgalasadrūpanānāguṇaviceṣṭitaḥ ॥19॥',
        'ityetatparamaṃ stotraṃ janamaṃgalasaṃjñitam ।\nyaḥ paṭhettena paṭhitaṃ bhavedvai sarvamaṃgalam ॥20॥',
        'yaḥ paṭhecchṛṇuyādbhaktyā trikālaṃ śrāvayecca vā ।\netattasya tu pāpāni naśyeyuḥ kila sarvaśaḥ ॥21॥',
        'etatsaṃsevamānānāṃ puruṣārthacatuṣṭaye ।\ndurlabhaṃ nāsti kimapi harikṛṣṇaprasādataḥ ॥22॥',
        'bhūtapretapiśācānāṃ ḍākinībrahmarākṣasām ।\nyoginīnāṃ tathā bālagrahādīnāmupadravaḥ ॥23॥',
        "abhicāro ripukṛto rogaścānyo'pyupadravaḥ ।\nayutāvartanādasya naśyatyeva na saṃśayaḥ ॥24॥",
        'daśāvṛtyā pratidinamasyābhīṣṭaṃ sukhaṃ bhavet ।\ngṛhibhistyāgibhiścāpi paṭhanīyamidaṃ tataḥ ॥25॥',
        '\n<b>।। iti śrīśatānandamuniviracitaṃ śrījanamaṃgalākhyaṃ śrīharyaṣṭottaraśatanāmastotraṃ saṃpūrṇam ।।</b>',
      ],
    },
  ],
};

export default niyam;
